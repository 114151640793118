import useSWR from 'swr'
import { requestGet } from '../utils/requestHelpers'

const useUserNotifications = ({ isAuthenticated } : { isAuthenticated: boolean | undefined }) => {
  const userNotificationsKey = isAuthenticated ? '/users/user-notifications' : null
  const { data: notifications, error, mutate } = useSWR(
    userNotificationsKey, 
    (url: string) => requestGet({ url })
  )

  if (notifications && Array.isArray(notifications.data)) {
    return {
      notifications: notifications.data,
      updateNotifications: () => mutate(),
      isLoading: !Array.isArray(notifications.data)
    }
  }
  return { 
    notifications: notifications,
    isLoading: !notifications && !error,
    updateNotifications: () => mutate()
  }
}

export default useUserNotifications