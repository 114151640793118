import { Box, Stack } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

type FeaturesSectionProps = {
  leftSection: ReactNode
  rightSection: ReactNode
}

const FeaturesSection: React.FC<FeaturesSectionProps> = ({ leftSection, rightSection }) => {
  return (
    <Stack w="100%" direction={['column', 'row']} align="basline" spacing={[2, 5]}>
      <Box flex={1}>{leftSection}</Box>
      <Box flex={1}>{rightSection}</Box>
    </Stack>
  )
}
export default FeaturesSection
