import { Button, Divider, Box } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HeaderSection } from '../../layouts'
import { theme, images } from '../../theme'

const GetStarted: React.FC = () => {
  const navigate = useNavigate()
  return (
    <HeaderSection
      headerTitle="Start your journey with Koin today"
      subHeaderText="Join over 2,000+ dimaond buyers and sellers using our platform."
      // bg={theme.colors.secondary.background[400]}
      bgImage={images.THeadBakground}
      pt={16}
    >
      <Box textAlign="center">
        <Button
          backgroundColor={theme.colors.primary[400]}
          color={theme.colors.white}
          _hover={{ backgroundColor: theme.colors.primary[400] }}
          onClick={() => {
            navigate('/signup')
          }}
        >
          Get Started
        </Button>
      </Box>
      <Divider pt={16} />
    </HeaderSection>
  )
}

export default GetStarted
