import { Stack } from '@chakra-ui/react'
import React from 'react'
import { HeaderSection } from '..'
import TeamMember from './TeamMember'

type TeamMember = {
  fullname: string
  role: string
  description: string
  photoSrc: string
}

const TeamSection: React.FC = () => {
  const teamMembers: TeamMember[] = [
    {
      fullname: 'Adam Schulman',
      role: 'Founder & CEO',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      photoSrc: 'https://i.pravatar.cc/500?img=61'
    },
    {
      fullname: 'Jacob xxxx',
      role: 'X Director Role',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      photoSrc: 'https://i.pravatar.cc/500?img=12'
    },
    {
      fullname: 'Mottie xxxx',
      role: 'X Director Role',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      photoSrc: 'https://i.pravatar.cc/500?img=57'
    }
  ]

  return (
    <HeaderSection
      headerTitle="Meet our team"
      subHeaderText="Our panel of directors have over 65+ years of combined experience within the diamond industry."
    >
      <Stack direction={['column', 'row']} spacing={5} alignItems="center" justifyContent="center">
        {teamMembers.map((teamMember) => (
          <TeamMember
           key={teamMember.fullname}
           fullname={teamMember.fullname}
           role={teamMember.role}
           description={teamMember.description}
           photoSrc={teamMember.photoSrc}
         />
        ))}
      </Stack>
    </HeaderSection>
  )
}
export default TeamSection
