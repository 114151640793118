import { ToastProps } from '@chakra-ui/react'
import { UploadFile } from '../types'

export const APP_NAME = 'Koin'

export const API_USER_STORAGE_KEY = 'koin-rest-api-user'

export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST

// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

export const BASE = process.env.REACT_APP_API_HOST || ''

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const SUCCESS_TOAST: ToastProps = {
  duration: 6000,
  isClosable: true,
  title: 'Success!',
  status: 'success',
  position: 'bottom-right'
}

export const ERROR_TOAST: ToastProps = {
  duration: 6000,
  title: 'Oops!',
  status: 'error',
  isClosable: true,
  position: 'bottom-right'
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')

export const COUNTRY_CODE = '+27'

export const EMPTY_FILE: UploadFile = {
  id: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  name: '',
  hash: '',
  mime: '',
  size: 0,
  url: '',
  provider: ''
}

export const industryOptions = [
  { label: 'Accounting', value: 'Accounting' },
  { label: 'Airlines/Aviation', value: 'Airlines/Aviation' },
  { label: 'Alternative Dispute Resolution', value: 'Alternative Dispute Resolution' },
  { label: 'Alternative Medicine', value: 'Alternative Medicine' },
  { label: 'Animation', value: 'Animation' },
  { label: 'Apparel/Fashion', value: 'Apparel/Fashion' },
  { label: 'Architecture/Planning', value: 'Architecture/Planning' },
  { label: 'Arts/Crafts', value: 'Arts/Crafts' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Aviation/Aerospace', value: 'Aviation/Aerospace' },
  { label: 'Banking/Mortgage', value: 'Banking/Mortgage' },
  { label: 'Biotechnology/Greentech', value: 'Biotechnology/Greentech' },
  { label: 'Broadcast Media', value: 'Broadcast Media' },
  { label: 'Building Materials', value: 'Building Materials' },
  { label: 'Business Supplies/Equipment', value: 'Business Supplies/Equipment' },
  {
    label: 'Capital Markets/Hedge Fund/Private Equity',
    value: 'Capital Markets/Hedge Fund/Private Equity'
  },
  { label: 'Chemicals', value: 'Chemicals' },
  { label: 'Civic/Social Organization', value: 'Civic/Social Organization' },
  { label: 'Civil Engineering', value: 'Civil Engineering' },
  { label: 'Commercial Real Estate', value: 'Commercial Real Estate' },
  { label: 'Computer Games', value: 'Computer Games' },
  { label: 'Computer Hardware', value: 'Computer Hardware' },
  { label: 'Computer Networking', value: 'Computer Networking' },
  { label: 'Computer Software/Engineering', value: 'Computer  Software/Engineering' },
  { label: 'Computer/Network Security', value: 'Computer/Network Security' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Consumer Electronics', value: 'Consumer Electronics' },
  { label: 'Consumer Goods', value: 'Consumer Goods' },
  { label: 'Consumer Services', value: 'Consumer Services' },
  { label: 'Cosmetics', value: 'Cosmetics' },
  { label: 'Dairy', value: 'Dairy' },
  { label: 'Defense/Space', value: 'Defense/Space' },
  { label: 'Design', value: 'Design' },
  { label: 'E-Learning', value: 'E-Learning' },
  { label: 'Education Management', value: 'Education Management' },
  { label: 'Electrical/Electronic Manufacturing', value: 'Electrical/Electronic Manufacturing' },
  { label: 'Entertainment/Movie Production', value: 'Entertainment/Movie Production' },
  { label: 'Environmental Services', value: 'Environmental Services' },
  { label: 'Events Services', value: 'Events Services' },
  { label: 'Executive Office', value: 'Executive Office' },
  { label: 'Facilities Services', value: 'Facilities Services' },
  { label: 'Farming', value: 'Farming' },
  { label: 'Financial Services', value: 'Financial Services' },
  { label: 'Fine Art', value: 'Fine Art' },
  { label: 'Fishery', value: 'Fishery' },
  { label: 'Food Production', value: 'Food Production' },
  { label: 'Food/Beverages', value: 'Food/Beverages' },
  { label: 'Fundraising', value: 'Fundraising' },
  { label: 'Furniture', value: 'Furniture' },
  { label: 'Gambling/Casinos', value: 'Gambling/Casinos' },
  { label: 'Glass/Ceramics/Concrete', value: 'Glass/Ceramics/Concrete' },
  { label: 'Government Administration', value: 'Government Administration' },
  { label: 'Government Relations', value: 'Government Relations' },
  { label: 'Graphic Design/Web Design', value: 'Graphic Design/Web Design' },
  { label: 'Health/Fitness', value: 'Health/Fitness' },
  { label: 'Higher Education/Acadamia', value: 'Higher Education/Acadamia' },
  { label: 'Hospital/Health Care', value: 'Hospital/Health Care' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Human Resources/HR', value: 'Human Resources/HR' },
  { label: 'Import/Export', value: 'Import/Export' },
  { label: 'Individual/Family Services', value: 'Individual/Family Services' },
  { label: 'Industrial Automation', value: 'Industrial Automation' },
  { label: 'Information Services', value: 'Information Services' },
  { label: 'Information Technology/IT', value: 'Information Technology/IT' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'International Affairs', value: 'International Affairs' },
  { label: 'International Trade/Development', value: 'International/Development' },
  { label: 'Internet', value: 'Internet' },
  { label: 'Investment Banking/Venture', value: 'Investment Banking/Venture' },
  {
    label: 'Investment Management/Hedge Fund/Private Equity',
    value: 'Investment Management/Hedge Fund/Private Equity'
  },
  { label: 'Judiciary', value: 'Judiciary' },
  { label: 'Law Enforcement', value: 'Law Enforcement' },
  { label: 'Law Practice/Law Firms', value: 'Law Practice/Law Firms' },
  { label: 'Legal Services', value: 'Legal Services' },
  { label: 'Legislative Office', value: 'Legislative Office' },
  { label: 'Leisure/Travel', value: 'Leisure/Travel' },
  { label: 'Library', value: 'Library' },
  { label: 'Logistics/Procurement', value: 'Logistics/Procurement' },
  { label: 'Luxury Goods/Jewelry', value: 'Luxury Goods/Jewelry' },
  { label: 'Machinery', value: 'Machinery' },
  { label: 'Management Consulting', value: 'Management Consulting' },
  { label: 'Maritime', value: 'Maritime' },
  { label: 'Market Research', value: 'Market Research' },
  { label: 'Marketing/Advertising/Sales', value: 'Marketing/Advertising/Sales' },
  { label: 'Mechanical or Industrial Engineering', value: 'Mechanical or Industrial Engineering' },
  { label: 'Media Production', value: 'Media Production' },
  { label: 'Medical Equipment', value: 'Medical Equipment' },
  { label: 'Medical Practice', value: 'Medical Practice' },
  { label: 'Mental Health Care', value: 'Mental Health Care' },
  { label: 'Military Industry', value: 'Military Industry' },
  { label: 'Mining/Metals', value: 'Mining/Metals' },
  { label: 'Motion Pictures/Film', value: 'Motion Pictures/Film' },
  { label: 'Museums/Institutions', value: 'Museums/Institutions' },
  { label: 'Music', value: 'Music' },
  { label: 'Nanotechnology', value: 'Nanotechnology' },
  { label: 'Newspapers/Journalism', value: 'Newspapers/Journalism' },
  { label: 'Non-Profit/Volunteering', value: 'Non-Profit/Volunteering' },
  { label: 'Oil/Energy/Solar/Greentech', value: 'Oil/Energy/Solar/Greentech' },
  { label: 'Online Publishing', value: 'Online Publishing' },
  { label: 'Other Industry', value: 'Other Industry' },
  { label: 'Outsourcing/Offshoring', value: 'Outsourcing/Offshoring' },
  { label: 'Package/Freight Delivery', value: 'Package/Freight Delivery' },
  { label: 'Packaging/Containers', value: 'Packaging/Containers' },
  { label: 'Paper/Forest Products', value: 'Paper/Forest Products' },
  { label: 'Performing Arts', value: 'Performing Arts' },
  { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
  { label: 'Philanthropy', value: 'Philanthropy' },
  { label: 'Photography', value: 'Photography' },
  { label: 'Plastics', value: 'Plastics' },
  { label: 'Political Organization', value: 'Political Organization' },
  { label: 'Primary/Secondary Education', value: 'Primary/Secondary Education' },
  { label: 'Printing', value: 'Printing' },
  { label: 'Professional Training', value: 'Professional Training' },
  { label: 'Program Development', value: 'Program Development' },
  { label: 'Public Relations/PR', value: 'Public Relations/PR' },
  { label: 'Public Safety', value: 'Public Safety' },
  { label: 'Publishing Industry', value: 'Publishing Industry' },
  { label: 'Railroad Manufacture', value: 'Railroad Manufacture' },
  { label: 'Ranching', value: 'Ranching' },
  { label: 'Real Estate/Mortgage', value: 'Real Estate/Mortgage' },
  { label: 'Recreational Facilities/Services', value: 'Recreational Facilities/Services' },
  { label: 'Religious Institutions', value: 'Religious Institutions' },
  { label: 'Renewables/Environment', value: 'Renewables/Environment' },
  { label: 'Research Industry', value: 'Research Industry' },
  { label: 'Restaurants', value: 'Restaurants' },
  { label: 'Retail Industry', value: 'Retail Industry' },
  { label: 'Security/Investigations', value: 'Security/Investigations' },
  { label: 'Semiconductors', value: 'Semiconductors' },
  { label: 'Shipbuilding', value: 'Shipbuilding' },
  { label: 'Sporting Goods', value: 'Sporting Goods' },
  { label: 'Sports', value: 'Sports' },
  { label: 'Staffing/Recruiting', value: 'Staffing/Recruiting' },
  { label: 'Supermarkets', value: 'Supermarkets' },
  { label: 'Telecommunications', value: 'Telecommunications' },
  { label: 'Textiles', value: 'Textiles' },
  { label: 'Think Tanks', value: 'Think Tanks' },
  { label: 'Tobacco', value: 'Tobacco' },
  { label: 'Translation/Localization', value: 'Translation/Localization' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Venture Capital/VC', value: 'Venture Capital/VC' },
  { label: 'Veterinary', value: 'Veterinary' },
  { label: 'Warehousing', value: 'Warehousing' },
  { label: 'Wholesale', value: 'Wholesale' },
  { label: 'Wine/Spirits', value: 'Wine' },
  { label: 'Wireless', value: 'Wireless' },
  { label: 'Writing/Editing', value: 'Writing/Editing' }
]

export const countryOptions = [
  { label: 'Afghanistan (AF)', value: 'Afghanistan (AF)' },
  { label: 'Åland Islands (AX)', value: 'Åland Islands (AX)' },
  { label: 'Albania (AL)', value: 'Albania (AL)' },
  { label: 'Algeria (DZ)', value: 'Algeria (DZ)' },
  { label: 'American Samoa (AS)', value: 'American Samoa (AS)' },
  { label: 'Andorra (AD)', value: 'Andorra (AD)' },
  { label: 'Angola (AO)', value: 'Angola (AO)' },
  { label: 'Anguilla (AI)', value: 'Anguilla (AI)' },
  { label: 'Antarctica (AQ)', value: 'Antarctica (AQ)' },
  { label: 'Antigua & Barbuda (AG)', value: 'Antigua & Barbuda (AG)' },
  { label: 'Argentina (AR)', value: 'Argentina (AR)' },
  { label: 'Armenia (AM)', value: 'Armenia (AM)' },
  { label: 'Aruba (AW)', value: 'Aruba (AW)' },
  { label: 'Australia (AU)', value: 'Australia (AU)' },
  { label: 'Austria (AT)', value: 'Austria (AT)' },
  { label: 'Azerbaijan (AZ)', value: 'Azerbaijan (AZ)' },
  { label: 'Bahamas (BS)', value: 'Bahamas (BS)' },
  { label: 'Bahrain (BH)', value: 'Bahrain (BH)' },
  { label: 'Bangladesh (BD)', value: 'Bangladesh (BD)' },
  { label: 'Barbados (BB)', value: 'Barbados (BB)' },
  { label: 'Belarus (BY)', value: 'Belarus (BY)' },
  { label: 'Belgium (BE)', value: 'Belgium (BE)' },
  { label: 'Belize (BZ)', value: 'Belize (BZ)' },
  { label: 'Benin (BJ)', value: 'Benin (BJ)' },
  { label: 'Bermuda (BM)', value: 'Bermuda (BM)' },
  { label: 'Bhutan (BT)', value: 'Bhutan (BT)' },
  { label: 'Bolivia (BO)', value: 'Bolivia (BO)' },
  { label: 'Bosnia & Herzegovina (BA)', value: 'Bosnia & Herzegovina (BA)' },
  { label: 'Botswana (BW)', value: 'Botswana (BW)' },
  { label: 'Bouvet Island (BV)', value: 'Bouvet Island (BV)' },
  { label: 'Brazil (BR)', value: 'Brazil (BR)' },
  { label: 'British Indian Ocean Territory (IO)', value: 'British Indian Ocean Territory (IO)' },
  { label: 'British Virgin Islands (VG)', value: 'British Virgin Islands (VG)' },
  { label: 'Brunei (BN)', value: 'Brunei (BN)' },
  { label: 'Bulgaria (BG)', value: 'Bulgaria (BG)' },
  { label: 'Burkina Faso (BF)', value: 'Burkina Faso (BF)' },
  { label: 'Burundi (BI)', value: 'Burundi (BI)' },
  { label: 'Cambodia (KH)', value: 'Cambodia (KH)' },
  { label: 'Cameroon (CM)', value: 'Cameroon (CM)' },
  { label: 'Canada (CA)', value: 'Canada (CA)' },
  { label: 'Cape Verde (CV)', value: 'Cape Verde (CV)' },
  { label: 'Caribbean Netherlands (BQ)', value: 'Caribbean Netherlands (BQ)' },
  { label: 'Cayman Islands (KY)', value: 'Cayman Islands (KY)' },
  { label: 'Central African Republic (CF)', value: 'Central African Republic (CF)' },
  { label: 'Chad (TD)', value: 'Chad (TD)' },
  { label: 'Chile (CL)', value: 'Chile (CL)' },
  { label: 'China (CN)', value: 'China (CN)' },
  { label: 'Christmas Island (CX)', value: 'Christmas Island (CX)' },
  { label: 'Cocos (Keeling) Islands (CC)', value: 'Cocos (Keeling)' },
  { label: 'Colombia (CO)', value: 'Colombia (CO)' },
  { label: 'Comoros (KM)', value: 'Comoros (KM)' },
  { label: 'Congo - Brazzaville (CG)', value: 'Congo - Brazzaville (CG)' },
  { label: 'Congo - Kinshasa (CD)', value: 'Congo - Kinshasa (CD)' },
  { label: 'Cook Islands (CK)', value: 'Cook Islands (CK)' },
  { label: 'Costa Rica (CR)', value: 'Costa Rica (CR)' },
  { label: 'Côte d’Ivoire (CI)', value: 'Côte d’Ivoire (CI)' },
  { label: 'Croatia (HR)', value: 'Croatia (HR)' },
  { label: 'Cuba (CU)', value: 'Cuba (CU)' },
  { label: 'Curaçao (CW)', value: 'Curaçao (CW)' },
  { label: 'Cyprus (CY)', value: 'Cyprus (CY)' },
  { label: 'Czechia (CZ)', value: 'Czechia (CZ)' },
  { label: 'Denmark (DK)', value: 'Denmark (DK)' },
  { label: 'Djibouti (DJ)', value: 'Djibouti (DJ)' },
  { label: 'Dominica (DM)', value: 'Dominica (DM)' },
  { label: 'Dominican Republic (DO)', value: 'Dominican Republic (DO)' },
  { label: 'Ecuador (EC)', value: 'Ecuador (EC)' },
  { label: 'Egypt (EG)', value: 'Egypt (EG)' },
  { label: 'El Salvador (SV)', value: 'El Salvador (SV)' },
  { label: 'Equatorial Guinea (GQ)', value: 'Equatorial Guinea (GQ)' },
  { label: 'Eritrea (ER)', value: 'Eritrea (ER)' },
  { label: 'Estonia (EE)', value: 'Estonia (EE)' },
  { label: 'Eswatini (SZ)', value: 'Eswatini (SZ)' },
  { label: 'Ethiopia (ET)', value: 'Ethiopia (ET)' },
  { label: 'Falkland Islands (FK)', value: 'Falkland Islands (FK)' },
  { label: 'Faroe Islands (FO)', value: 'Faroe Islands (FO)' },
  { label: 'Fiji (FJ)', value: 'Fiji (FJ)' },
  { label: 'Finland (FI)', value: 'Finland (FI)' },
  { label: 'France (FR)', value: 'France (FR)' },
  { label: 'French Guiana (GF)', value: 'French Guiana (GF)' },
  { label: 'French Polynesia (PF)', value: 'French Polynesia (PF)' },
  { label: 'French Southern Territories (TF)', value: 'French Southern (TF)' },
  { label: 'Gabon (GA)', value: 'Gabon (GA)' },
  { label: 'Gambia (GM)', value: 'Gambia (GM)' },
  { label: 'Georgia (GE)', value: 'Georgia (GE)' },
  { label: 'Germany (DE)', value: 'Germany (DE)' },
  { label: 'Ghana (GH)', value: 'Ghana (GH)' },
  { label: 'Gibraltar (GI)', value: 'Gibraltar (GI)' },
  { label: 'Greece (GR)', value: 'Greece (GR)' },
  { label: 'Greenland (GL)', value: 'Greenland (GL)' },
  { label: 'Grenada (GD)', value: 'Grenada (GD)' },
  { label: 'Guadeloupe (GP)', value: 'Guadeloupe (GP)' },
  { label: 'Guam (GU)', value: 'Guam (GU)' },
  { label: 'Guatemala (GT)', value: 'Guatemala (GT)' },
  { label: 'Guernsey (GG)', value: 'Guernsey (GG)' },
  { label: 'Guinea (GN)', value: 'Guinea (GN)' },
  { label: 'Guinea-Bissau (GW)', value: 'Guinea-Bissau (GW)' },
  { label: 'Guyana (GY)', value: 'Guyana (GY)' },
  { label: 'Haiti (HT)', value: 'Haiti (HT)' },
  { label: 'Heard & McDonald Islands (HM)', value: 'Heard & McDonald Islands (HM)' },
  { label: 'Honduras (HN)', value: 'Honduras (HN)' },
  { label: 'Hong Kong SAR China (HK)', value: 'Hong Kong SAR China (HK)' },
  { label: 'Hungary (HU)', value: 'Hungary (HU)' },
  { label: 'Iceland (IS)', value: 'Iceland (IS)' },
  { label: 'India (IN)', value: 'India (IN)' },
  { label: 'Indonesia (ID)', value: 'Indonesia (ID)' },
  { label: 'Iran (IR)', value: 'Iran (IR)' },
  { label: 'Iraq (IQ)', value: 'Iraq (IQ)' },
  { label: 'Ireland (IE)', value: 'Ireland (IE)' },
  { label: 'Isle of Man (IM)', value: 'Isle of ' },
  { label: 'Israel (IL)', value: 'Israel (IL)' },
  { label: 'Italy (IT)', value: 'Italy (IT)' },
  { label: 'Jamaica (JM)', value: 'Jamaica (JM)' },
  { label: 'Japan (JP)', value: 'Japan (JP)' },
  { label: 'Jersey (JE)', value: 'Jersey (JE)' },
  { label: 'Jordan (JO)', value: 'Jordan (JO)' },
  { label: 'Kazakhstan (KZ)', value: 'Kazakhstan (KZ)' },
  { label: 'Kenya (KE)', value: 'Kenya (KE)' },
  { label: 'Kiribati (KI)', value: 'Kiribati (KI)' },
  { label: 'Kuwait (KW)', value: 'Kuwait (KW)' },
  { label: 'Kyrgyzstan (KG)', value: 'Kyrgyzstan (KG)' },
  { label: 'Laos (LA)', value: 'Laos (LA)' },
  { label: 'Latvia (LV)', value: 'Latvia (LV)' },
  { label: 'Lebanon (LB)', value: 'Lebanon (LB)' },
  { label: 'Lesotho (LS)', value: 'Lesotho (LS)' },
  { label: 'Liberia (LR)', value: 'Liberia (LR)' },
  { label: 'Libya (LY)', value: 'Libya (LY)' },
  { label: 'Liechtenstein (LI)', value: 'Liechtenstein (LI)' },
  { label: 'Lithuania (LT)', value: 'Lithuania (LT)' },
  { label: 'Luxembourg (LU)', value: 'Luxembourg (LU)' },
  { label: 'Macao SAR China (MO)', value: 'Macao SAR China (MO)' },
  { label: 'Madagascar (MG)', value: 'Madagascar (MG)' },
  { label: 'Malawi (MW)', value: 'Malawi (MW)' },
  { label: 'Malaysia (MY)', value: 'Malaysia (MY)' },
  { label: 'Maldives (MV)', value: 'Maldives (MV)' },
  { label: 'Mali (ML)', value: 'Mali (ML)' },
  { label: 'Malta (MT)', value: 'Malta (MT)' },
  { label: 'Marshall Islands (MH)', value: 'Marshall Islands  (MH)' },
  { label: 'Martinique (MQ)', value: 'Martinique (MQ)' },
  { label: 'Mauritania (MR)', value: 'Mauritania (MR)' },
  { label: 'Mauritius (MU)', value: 'Mauritius (MU)' },
  { label: 'Mayotte (YT)', value: 'Mayotte (YT)' },
  { label: 'Mexico (MX)', value: 'Mexico (MX)' },
  { label: 'Micronesia (FM)', value: 'Micronesia (FM)' },
  { label: 'Moldova (MD)', value: 'Moldova (MD)' },
  { label: 'Monaco (MC)', value: 'Monaco (MC)' },
  { label: 'Mongolia (MN)', value: 'Mongolia (MN)' },
  { label: 'Montenegro (ME)', value: 'Montenegro (ME)' },
  { label: 'Montserrat (MS)', value: 'Montserrat (MS)' },
  { label: 'Morocco (MA)', value: 'Morocco (MA)' },
  { label: 'Mozambique (MZ)', value: 'Mozambique (MZ)' },
  { label: 'Myanmar (Burma) (MM)', value: 'Myanmar (Burma)' },
  { label: 'Namibia (NA)', value: 'Namibia (NA)' },
  { label: 'Nauru (NR)', value: 'Nauru (NR)' },
  { label: 'Nepal (NP)', value: 'Nepal (NP)' },
  { label: 'Netherlands (NL)', value: 'Netherlands (NL)' },
  { label: 'New Caledonia (NC)', value: 'New Caledonia (NC)' },
  { label: 'New Zealand (NZ)', value: 'New Zealand (NZ)' },
  { label: 'Nicaragua (NI)', value: 'Nicaragua (NI)' },
  { label: 'Niger (NE)', value: 'Niger (NE)' },
  { label: 'Nigeria (NG)', value: 'Nigeria (NG)' },
  { label: 'Niue (NU)', value: 'Niue (NU)' },
  { label: 'Norfolk Island (NF)', value: 'Norfolk Island (NF)' },
  { label: 'North Korea (KP)', value: 'North Korea (KP)' },
  { label: 'North Macedonia (MK)', value: 'North Macedonia (MK)' },
  { label: 'Northern Mariana Islands (MP)', value: 'Northern Mariana Islands (MP)' },
  { label: 'Norway (NO)', value: 'Norway (NO)' },
  { label: 'Oman (OM)', value: 'Oman (OM)' },
  { label: 'Pakistan (PK)', value: 'Pakistan (PK)' },
  { label: 'Palau (PW)', value: 'Palau (PW)' },
  { label: 'Palestinian Territories (PS)', value: 'Palestinian Territories (PS)' },
  { label: 'Panama (PA)', value: 'Panama (PA)' },
  { label: 'Papua New Guinea (PG)', value: 'Papua New Guinea (PG)' },
  { label: 'Paraguay (PY)', value: 'Paraguay (PY)' },
  { label: 'Peru (PE)', value: 'Peru (PE)' },
  { label: 'Philippines (PH)', value: 'Philippines (PH)' },
  { label: 'Pitcairn Islands (PN)', value: 'Pitcairn Islands (PN)' },
  { label: 'Poland (PL)', value: 'Poland (PL)' },
  { label: 'Portugal (PT)', value: 'Portugal (PT)' },
  { label: 'Puerto Rico (PR)', value: 'Puerto Rico (PR)' },
  { label: 'Qatar (QA)', value: 'Qatar (QA)' },
  { label: 'Réunion (RE)', value: 'Réunion (RE)' },
  { label: 'Romania (RO)', value: 'Romania (RO)' },
  { label: 'Russia (RU)', value: 'Russia (RU)' },
  { label: 'Rwanda (RW)', value: 'Rwanda (RW)' },
  { label: 'Samoa (WS)', value: 'Samoa (WS)' },
  { label: 'San Marino (SM)', value: 'San Marino (SM)' },
  { label: 'São Tomé & Príncipe (ST)', value: 'São Tomé & Príncipe (ST)' },
  { label: 'Saudi Arabia (SA)', value: 'Saudi Arabia (SA)' },
  { label: 'Senegal (SN)', value: 'Senegal (SN)' },
  { label: 'Serbia (RS)', value: 'Serbia (RS)' },
  { label: 'Seychelles (SC)', value: 'Seychelles (SC)' },
  { label: 'Sierra Leone (SL)', value: 'Sierra Leone (SL)' },
  { label: 'Singapore (SG)', value: 'Singapore (SG)' },
  { label: 'Sint Maarten (SX)', value: 'Sint Maarten (SX)' },
  { label: 'Slovakia (SK)', value: 'Slovakia (SK)' },
  { label: 'Slovenia (SI)', value: 'Slovenia (SI)' },
  { label: 'Solomon Islands (SB)', value: 'Solomon Islands (SB)' },
  { label: 'Somalia (SO)', value: 'Somalia (SO)' },
  { label: 'South Africa (ZA)', value: 'South Africa ' },
  {
    label: 'South Georgia & South Sandwich Islands (GS)',
    value: 'South Georgia & South Sandwich Islands (GS)'
  },
  { label: 'South Korea (KR)', value: 'South Korea (KR)' },
  { label: 'South Sudan (SS)', value: 'South Sudan (SS)' },
  { label: 'Spain (ES)', value: 'Spain (ES)' },
  { label: 'Sri Lanka (LK)', value: 'Sri Lanka (LK)' },
  { label: 'St. Barthélemy (BL)', value: 'St. Barthélemy (BL)' },
  { label: 'St. Helena (SH)', value: 'St.  Helena (SH)' },
  { label: 'St. Kitts & Nevis (KN)', value: 'St. Kitts & Nevis (KN)' },
  { label: 'St. Lucia (LC)', value: 'St. Lucia (LC)' },
  { label: 'St. Martin (MF)', value: 'St. Martin (MF)' },
  { label: 'St. Pierre & Miquelon (PM)', value: 'St. Pierre & Miquelon (PM)' },
  { label: 'St. Vincent & Grenadines (VC)', value: 'St. Vincent & Grenadines (VC)' },
  { label: 'Sudan (SD)', value: 'Sudan (SD)' },
  { label: 'Suriname (SR)', value: 'Suriname (SR)' },
  { label: 'Svalbard & Jan Mayen (SJ)', value: 'Svalbard & Jan Mayen (SJ)' },
  { label: 'Sweden (SE)', value: 'Sweden (SE)' },
  { label: 'Switzerland (CH)', value: 'Switzerland (CH)' },
  { label: 'Syria (SY)', value: 'Syria (SY)' },
  { label: 'Taiwan (TW)', value: 'Taiwan (TW)' },
  { label: 'Tajikistan (TJ)', value: 'Tajikistan (TJ)' },
  { label: 'Tanzania (TZ)', value: 'Tanzania (TZ)' },
  { label: 'Thailand (TH)', value: 'Thailand (TH)' },
  { label: 'Timor-Leste (TL)', value: 'Timor-Leste (TL)' },
  { label: 'Togo (TG)', value: 'Togo (TG)' },
  { label: 'Tokelau (TK)', value: 'Tokelau (TK)' },
  { label: 'Tonga (TO)', value: 'Tonga (TO)' },
  { label: 'Trinidad & Tobago (TT)', value: 'Trinidad & Tobago (TT)' },
  { label: 'Tunisia (TN)', value: 'Tunisia (TN)' },
  { label: 'Turkey (TR)', value: 'Turkey (TR)' },
  { label: 'Turkmenistan (TM)', value: 'Turkmenistan (TM)' },
  { label: 'Turks & Caicos Islands (TC)', value: 'Turks & Caicos Islands (TC)' },
  { label: 'Tuvalu (TV)', value: 'Tuvalu (TV)' },
  { label: 'U.S. Outlying Islands (UM)', value: 'U.S. Outlying Islands (UM)' },
  { label: 'U.S. Virgin Islands (VI)', value: 'U.S. Virgin Islands (VI)' },
  { label: 'Uganda (UG)', value: 'Uganda (UG)' },
  { label: 'Ukraine (UA)', value: 'Ukraine (UA)' },
  { label: 'United Arab Emirates (AE)', value: 'United Arab Emirates (AE)' },
  { label: 'United Kingdom (GB)', value: 'United Kingdom (GB)' },
  { label: 'United States (US)', value: 'United States (US)' },
  { label: 'Uruguay (UY)', value: 'Uruguay (UY)' },
  { label: 'Uzbekistan (UZ)', value: 'Uzbekistan (UZ)' },
  { label: 'Vanuatu (VU)', value: 'Vanuatu (VU)' },
  { label: 'Vatican City (VA)', value: 'Vatican City (VA)' },
  { label: 'Venezuela (VE)', value: 'Venezuela (VE)' },
  { label: 'Vietnam (VN)', value: 'Vietnam (VN)' },
  { label: 'Wallis & Futuna (WF)', value: 'Wallis & Futuna (WF)' },
  { label: 'Western Sahara (EH)', value: 'Western Sahara (EH)' },
  { label: 'Yemen (YE)', value: 'Yemen (YE)' },
  { label: 'Zambia (ZM)', value: 'Zambia (ZM)' },
  { label: 'Zimbabwe (ZW)', value: 'Zimbabwe (ZW)' }
]

export const countryISO = [
  {
    "ISO2": "AF",
    "ISO3": "AFG",
    "countryName": "Afghanistan",
    "phoneCode": "93"
  },
  {
    "ISO2": "AL",
    "ISO3": "ALB",
    "countryName": "Albania",
    "phoneCode": "355"
  },
  {
    "ISO2": "DZ",
    "ISO3": "DZA",
    "countryName": "Algeria",
    "phoneCode": "213"
  },
  {
    "ISO2": "AS",
    "ISO3": "ASM",
    "countryName": "American Samoa",
    "phoneCode": "1-684"
  },
  {
    "ISO2": "AD",
    "ISO3": "AND",
    "countryName": "Andorra",
    "phoneCode": "376"
  },
  {
    "ISO2": "AO",
    "ISO3": "AGO",
    "countryName": "Angola",
    "phoneCode": "244"
  },
  {
    "ISO2": "AI",
    "ISO3": "AIA",
    "countryName": "Anguilla",
    "phoneCode": "1-264"
  },
  {
    "ISO2": "AQ",
    "ISO3": "ATA",
    "countryName": "Antarctica",
    "phoneCode": "672"
  },
  {
    "ISO2": "AG",
    "ISO3": "ATG",
    "countryName": "Antigua and Barbuda",
    "phoneCode": "1-268"
  },
  {
    "ISO2": "AR",
    "ISO3": "ARG",
    "countryName": "Argentina",
    "phoneCode": "54"
  },
  {
    "ISO2": "AM",
    "ISO3": "ARM",
    "countryName": "Armenia",
    "phoneCode": "374"
  },
  {
    "ISO2": "AW",
    "ISO3": "ABW",
    "countryName": "Aruba",
    "phoneCode": "297"
  },
  {
    "ISO2": "AU",
    "ISO3": "AUS",
    "countryName": "Australia",
    "phoneCode": "61"
  },
  {
    "ISO2": "AT",
    "ISO3": "AUT",
    "countryName": "Austria",
    "phoneCode": "43"
  },
  {
    "ISO2": "AZ",
    "ISO3": "AZE",
    "countryName": "Azerbaijan",
    "phoneCode": "994"
  },
  {
    "ISO2": "BS",
    "ISO3": "BHS",
    "countryName": "Bahamas",
    "phoneCode": "1-242"
  },
  {
    "ISO2": "BH",
    "ISO3": "BHR",
    "countryName": "Bahrain",
    "phoneCode": "973"
  },
  {
    "ISO2": "BD",
    "ISO3": "BGD",
    "countryName": "Bangladesh",
    "phoneCode": "880"
  },
  {
    "ISO2": "BB",
    "ISO3": "BRB",
    "countryName": "Barbados",
    "phoneCode": "1-246"
  },
  {
    "ISO2": "BY",
    "ISO3": "BLR",
    "countryName": "Belarus",
    "phoneCode": "375"
  },
  {
    "ISO2": "BE",
    "ISO3": "BEL",
    "countryName": "Belgium",
    "phoneCode": "32"
  },
  {
    "ISO2": "BZ",
    "ISO3": "BLZ",
    "countryName": "Belize",
    "phoneCode": "501"
  },
  {
    "ISO2": "BJ",
    "ISO3": "BEN",
    "countryName": "Benin",
    "phoneCode": "229"
  },
  {
    "ISO2": "BM",
    "ISO3": "BMU",
    "countryName": "Bermuda",
    "phoneCode": "1-441"
  },
  {
    "ISO2": "BT",
    "ISO3": "BTN",
    "countryName": "Bhutan",
    "phoneCode": "975"
  },
  {
    "ISO2": "BO",
    "ISO3": "BOL",
    "countryName": "Bolivia",
    "phoneCode": "591"
  },
  {
    "ISO2": "BA",
    "ISO3": "BIH",
    "countryName": "Bosnia and Herzegovina",
    "phoneCode": "387"
  },
  {
    "ISO2": "BW",
    "ISO3": "BWA",
    "countryName": "Botswana",
    "phoneCode": "267"
  },
  {
    "ISO2": "BR",
    "ISO3": "BRA",
    "countryName": "Brazil",
    "phoneCode": "55"
  },
  {
    "ISO2": "IO",
    "ISO3": "IOT",
    "countryName": "British Indian Ocean Territory",
    "phoneCode": "246"
  },
  {
    "ISO2": "VG",
    "ISO3": "VGB",
    "countryName": "British Virgin Islands",
    "phoneCode": "1-284"
  },
  {
    "ISO2": "BN",
    "ISO3": "BRN",
    "countryName": "Brunei",
    "phoneCode": "673"
  },
  {
    "ISO2": "BG",
    "ISO3": "BGR",
    "countryName": "Bulgaria",
    "phoneCode": "359"
  },
  {
    "ISO2": "BF",
    "ISO3": "BFA",
    "countryName": "Burkina Faso",
    "phoneCode": "226"
  },
  {
    "ISO2": "BI",
    "ISO3": "BDI",
    "countryName": "Burundi",
    "phoneCode": "257"
  },
  {
    "ISO2": "KH",
    "ISO3": "KHM",
    "countryName": "Cambodia",
    "phoneCode": "855"
  },
  {
    "ISO2": "CM",
    "ISO3": "CMR",
    "countryName": "Cameroon",
    "phoneCode": "237"
  },
  {
    "ISO2": "CA",
    "ISO3": "CAN",
    "countryName": "Canada",
    "phoneCode": "1"
  },
  {
    "ISO2": "CV",
    "ISO3": "CPV",
    "countryName": "Cape Verde",
    "phoneCode": "238"
  },
  {
    "ISO2": "KY",
    "ISO3": "CYM",
    "countryName": "Cayman Islands",
    "phoneCode": "1-345"
  },
  {
    "ISO2": "CF",
    "ISO3": "CAF",
    "countryName": "Central African Republic",
    "phoneCode": "236"
  },
  {
    "ISO2": "TD",
    "ISO3": "TCD",
    "countryName": "Chad",
    "phoneCode": "235"
  },
  {
    "ISO2": "CL",
    "ISO3": "CHL",
    "countryName": "Chile",
    "phoneCode": "56"
  },
  {
    "ISO2": "CN",
    "ISO3": "CHN",
    "countryName": "China",
    "phoneCode": "86"
  },
  {
    "ISO2": "CX",
    "ISO3": "CXR",
    "countryName": "Christmas Island",
    "phoneCode": "61"
  },
  {
    "ISO2": "CC",
    "ISO3": "CCK",
    "countryName": "Cocos Islands",
    "phoneCode": "61"
  },
  {
    "ISO2": "CO",
    "ISO3": "COL",
    "countryName": "Colombia",
    "phoneCode": "57"
  },
  {
    "ISO2": "KM",
    "ISO3": "COM",
    "countryName": "Comoros",
    "phoneCode": "269"
  },
  {
    "ISO2": "CK",
    "ISO3": "COK",
    "countryName": "Cook Islands",
    "phoneCode": "682"
  },
  {
    "ISO2": "CR",
    "ISO3": "CRI",
    "countryName": "Costa Rica",
    "phoneCode": "506"
  },
  {
    "ISO2": "HR",
    "ISO3": "HRV",
    "countryName": "Croatia",
    "phoneCode": "385"
  },
  {
    "ISO2": "CU",
    "ISO3": "CUB",
    "countryName": "Cuba",
    "phoneCode": "53"
  },
  {
    "ISO2": "CW",
    "ISO3": "CUW",
    "countryName": "Curacao",
    "phoneCode": "599"
  },
  {
    "ISO2": "CY",
    "ISO3": "CYP",
    "countryName": "Cyprus",
    "phoneCode": "357"
  },
  {
    "ISO2": "CZ",
    "ISO3": "CZE",
    "countryName": "Czech Republic",
    "phoneCode": "420"
  },
  {
    "ISO2": "CD",
    "ISO3": "COD",
    "countryName": "Democratic Republic of the Congo",
    "phoneCode": "243"
  },
  {
    "ISO2": "DK",
    "ISO3": "DNK",
    "countryName": "Denmark",
    "phoneCode": "45"
  },
  {
    "ISO2": "DJ",
    "ISO3": "DJI",
    "countryName": "Djibouti",
    "phoneCode": "253"
  },
  {
    "ISO2": "DM",
    "ISO3": "DMA",
    "countryName": "Dominica",
    "phoneCode": "1-767"
  },
  {
    "ISO2": "DO",
    "ISO3": "DOM",
    "countryName": "Dominican Republic",
    "phoneCode": "1-809, 1-829, 1-849"
  },
  {
    "ISO2": "TL",
    "ISO3": "TLS",
    "countryName": "East Timor",
    "phoneCode": "670"
  },
  {
    "ISO2": "EC",
    "ISO3": "ECU",
    "countryName": "Ecuador",
    "phoneCode": "593"
  },
  {
    "ISO2": "EG",
    "ISO3": "EGY",
    "countryName": "Egypt",
    "phoneCode": "20"
  },
  {
    "ISO2": "SV",
    "ISO3": "SLV",
    "countryName": "El Salvador",
    "phoneCode": "503"
  },
  {
    "ISO2": "GQ",
    "ISO3": "GNQ",
    "countryName": "Equatorial Guinea",
    "phoneCode": "240"
  },
  {
    "ISO2": "ER",
    "ISO3": "ERI",
    "countryName": "Eritrea",
    "phoneCode": "291"
  },
  {
    "ISO2": "EE",
    "ISO3": "EST",
    "countryName": "Estonia",
    "phoneCode": "372"
  },
  {
    "ISO2": "ET",
    "ISO3": "ETH",
    "countryName": "Ethiopia",
    "phoneCode": "251"
  },
  {
    "ISO2": "FK",
    "ISO3": "FLK",
    "countryName": "Falkland Islands",
    "phoneCode": "500"
  },
  {
    "ISO2": "FO",
    "ISO3": "FRO",
    "countryName": "Faroe Islands",
    "phoneCode": "298"
  },
  {
    "ISO2": "FJ",
    "ISO3": "FJI",
    "countryName": "Fiji",
    "phoneCode": "679"
  },
  {
    "ISO2": "FI",
    "ISO3": "FIN",
    "countryName": "Finland",
    "phoneCode": "358"
  },
  {
    "ISO2": "FR",
    "ISO3": "FRA",
    "countryName": "France",
    "phoneCode": "33"
  },
  {
    "ISO2": "PF",
    "ISO3": "PYF",
    "countryName": "French Polynesia",
    "phoneCode": "689"
  },
  {
    "ISO2": "GA",
    "ISO3": "GAB",
    "countryName": "Gabon",
    "phoneCode": "241"
  },
  {
    "ISO2": "GM",
    "ISO3": "GMB",
    "countryName": "Gambia",
    "phoneCode": "220"
  },
  {
    "ISO2": "GE",
    "ISO3": "GEO",
    "countryName": "Georgia",
    "phoneCode": "995"
  },
  {
    "ISO2": "DE",
    "ISO3": "DEU",
    "countryName": "Germany",
    "phoneCode": "49"
  },
  {
    "ISO2": "GH",
    "ISO3": "GHA",
    "countryName": "Ghana",
    "phoneCode": "233"
  },
  {
    "ISO2": "GI",
    "ISO3": "GIB",
    "countryName": "Gibraltar",
    "phoneCode": "350"
  },
  {
    "ISO2": "GR",
    "ISO3": "GRC",
    "countryName": "Greece",
    "phoneCode": "30"
  },
  {
    "ISO2": "GL",
    "ISO3": "GRL",
    "countryName": "Greenland",
    "phoneCode": "299"
  },
  {
    "ISO2": "GD",
    "ISO3": "GRD",
    "countryName": "Grenada",
    "phoneCode": "1-473"
  },
  {
    "ISO2": "GU",
    "ISO3": "GUM",
    "countryName": "Guam",
    "phoneCode": "1-671"
  },
  {
    "ISO2": "GT",
    "ISO3": "GTM",
    "countryName": "Guatemala",
    "phoneCode": "502"
  },
  {
    "ISO2": "GG",
    "ISO3": "GGY",
    "countryName": "Guernsey",
    "phoneCode": "44-1481"
  },
  {
    "ISO2": "GN",
    "ISO3": "GIN",
    "countryName": "Guinea",
    "phoneCode": "224"
  },
  {
    "ISO2": "GW",
    "ISO3": "GNB",
    "countryName": "Guinea-Bissau",
    "phoneCode": "245"
  },
  {
    "ISO2": "GY",
    "ISO3": "GUY",
    "countryName": "Guyana",
    "phoneCode": "592"
  },
  {
    "ISO2": "HT",
    "ISO3": "HTI",
    "countryName": "Haiti",
    "phoneCode": "509"
  },
  {
    "ISO2": "HN",
    "ISO3": "HND",
    "countryName": "Honduras",
    "phoneCode": "504"
  },
  {
    "ISO2": "HK",
    "ISO3": "HKG",
    "countryName": "Hong Kong",
    "phoneCode": "852"
  },
  {
    "ISO2": "HU",
    "ISO3": "HUN",
    "countryName": "Hungary",
    "phoneCode": "36"
  },
  {
    "ISO2": "IS",
    "ISO3": "ISL",
    "countryName": "Iceland",
    "phoneCode": "354"
  },
  {
    "ISO2": "IN",
    "ISO3": "IND",
    "countryName": "India",
    "phoneCode": "91"
  },
  {
    "ISO2": "ID",
    "ISO3": "IDN",
    "countryName": "Indonesia",
    "phoneCode": "62"
  },
  {
    "ISO2": "IR",
    "ISO3": "IRN",
    "countryName": "Iran",
    "phoneCode": "98"
  },
  {
    "ISO2": "IQ",
    "ISO3": "IRQ",
    "countryName": "Iraq",
    "phoneCode": "964"
  },
  {
    "ISO2": "IE",
    "ISO3": "IRL",
    "countryName": "Ireland",
    "phoneCode": "353"
  },
  {
    "ISO2": "IM",
    "ISO3": "IMN",
    "countryName": "Isle of Man",
    "phoneCode": "44-1624"
  },
  {
    "ISO2": "IL",
    "ISO3": "ISR",
    "countryName": "Israel",
    "phoneCode": "972"
  },
  {
    "ISO2": "IT",
    "ISO3": "ITA",
    "countryName": "Italy",
    "phoneCode": "39"
  },
  {
    "ISO2": "CI",
    "ISO3": "CIV",
    "countryName": "Ivory Coast",
    "phoneCode": "225"
  },
  {
    "ISO2": "JM",
    "ISO3": "JAM",
    "countryName": "Jamaica",
    "phoneCode": "1-876"
  },
  {
    "ISO2": "JP",
    "ISO3": "JPN",
    "countryName": "Japan",
    "phoneCode": "81"
  },
  {
    "ISO2": "JE",
    "ISO3": "JEY",
    "countryName": "Jersey",
    "phoneCode": "44-1534"
  },
  {
    "ISO2": "JO",
    "ISO3": "JOR",
    "countryName": "Jordan",
    "phoneCode": "962"
  },
  {
    "ISO2": "KZ",
    "ISO3": "KAZ",
    "countryName": "Kazakhstan",
    "phoneCode": "7"
  },
  {
    "ISO2": "KE",
    "ISO3": "KEN",
    "countryName": "Kenya",
    "phoneCode": "254"
  },
  {
    "ISO2": "KI",
    "ISO3": "KIR",
    "countryName": "Kiribati",
    "phoneCode": "686"
  },
  {
    "ISO2": "XK",
    "ISO3": "XKX",
    "countryName": "Kosovo",
    "phoneCode": "383"
  },
  {
    "ISO2": "KW",
    "ISO3": "KWT",
    "countryName": "Kuwait",
    "phoneCode": "965"
  },
  {
    "ISO2": "KG",
    "ISO3": "KGZ",
    "countryName": "Kyrgyzstan",
    "phoneCode": "996"
  },
  {
    "ISO2": "LA",
    "ISO3": "LAO",
    "countryName": "Laos",
    "phoneCode": "856"
  },
  {
    "ISO2": "LV",
    "ISO3": "LVA",
    "countryName": "Latvia",
    "phoneCode": "371"
  },
  {
    "ISO2": "LB",
    "ISO3": "LBN",
    "countryName": "Lebanon",
    "phoneCode": "961"
  },
  {
    "ISO2": "LS",
    "ISO3": "LSO",
    "countryName": "Lesotho",
    "phoneCode": "266"
  },
  {
    "ISO2": "LR",
    "ISO3": "LBR",
    "countryName": "Liberia",
    "phoneCode": "231"
  },
  {
    "ISO2": "LY",
    "ISO3": "LBY",
    "countryName": "Libya",
    "phoneCode": "218"
  },
  {
    "ISO2": "LI",
    "ISO3": "LIE",
    "countryName": "Liechtenstein",
    "phoneCode": "423"
  },
  {
    "ISO2": "LT",
    "ISO3": "LTU",
    "countryName": "Lithuania",
    "phoneCode": "370"
  },
  {
    "ISO2": "LU",
    "ISO3": "LUX",
    "countryName": "Luxembourg",
    "phoneCode": "352"
  },
  {
    "ISO2": "MO",
    "ISO3": "MAC",
    "countryName": "Macau",
    "phoneCode": "853"
  },
  {
    "ISO2": "MK",
    "ISO3": "MKD",
    "countryName": "Macedonia",
    "phoneCode": "389"
  },
  {
    "ISO2": "MG",
    "ISO3": "MDG",
    "countryName": "Madagascar",
    "phoneCode": "261"
  },
  {
    "ISO2": "MW",
    "ISO3": "MWI",
    "countryName": "Malawi",
    "phoneCode": "265"
  },
  {
    "ISO2": "MY",
    "ISO3": "MYS",
    "countryName": "Malaysia",
    "phoneCode": "60"
  },
  {
    "ISO2": "MV",
    "ISO3": "MDV",
    "countryName": "Maldives",
    "phoneCode": "960"
  },
  {
    "ISO2": "ML",
    "ISO3": "MLI",
    "countryName": "Mali",
    "phoneCode": "223"
  },
  {
    "ISO2": "MT",
    "ISO3": "MLT",
    "countryName": "Malta",
    "phoneCode": "356"
  },
  {
    "ISO2": "MH",
    "ISO3": "MHL",
    "countryName": "Marshall Islands",
    "phoneCode": "692"
  },
  {
    "ISO2": "MR",
    "ISO3": "MRT",
    "countryName": "Mauritania",
    "phoneCode": "222"
  },
  {
    "ISO2": "MU",
    "ISO3": "MUS",
    "countryName": "Mauritius",
    "phoneCode": "230"
  },
  {
    "ISO2": "YT",
    "ISO3": "MYT",
    "countryName": "Mayotte",
    "phoneCode": "262"
  },
  {
    "ISO2": "MX",
    "ISO3": "MEX",
    "countryName": "Mexico",
    "phoneCode": "52"
  },
  {
    "ISO2": "FM",
    "ISO3": "FSM",
    "countryName": "Micronesia",
    "phoneCode": "691"
  },
  {
    "ISO2": "MD",
    "ISO3": "MDA",
    "countryName": "Moldova",
    "phoneCode": "373"
  },
  {
    "ISO2": "MC",
    "ISO3": "MCO",
    "countryName": "Monaco",
    "phoneCode": "377"
  },
  {
    "ISO2": "MN",
    "ISO3": "MNG",
    "countryName": "Mongolia",
    "phoneCode": "976"
  },
  {
    "ISO2": "ME",
    "ISO3": "MNE",
    "countryName": "Montenegro",
    "phoneCode": "382"
  },
  {
    "ISO2": "MS",
    "ISO3": "MSR",
    "countryName": "Montserrat",
    "phoneCode": "1-664"
  },
  {
    "ISO2": "MA",
    "ISO3": "MAR",
    "countryName": "Morocco",
    "phoneCode": "212"
  },
  {
    "ISO2": "MZ",
    "ISO3": "MOZ",
    "countryName": "Mozambique",
    "phoneCode": "258"
  },
  {
    "ISO2": "MM",
    "ISO3": "MMR",
    "countryName": "Myanmar",
    "phoneCode": "95"
  },
  {
    "ISO2": "NA",
    "ISO3": "NAM",
    "countryName": "Namibia",
    "phoneCode": "264"
  },
  {
    "ISO2": "NR",
    "ISO3": "NRU",
    "countryName": "Nauru",
    "phoneCode": "674"
  },
  {
    "ISO2": "NP",
    "ISO3": "NPL",
    "countryName": "Nepal",
    "phoneCode": "977"
  },
  {
    "ISO2": "NL",
    "ISO3": "NLD",
    "countryName": "Netherlands",
    "phoneCode": "31"
  },
  {
    "ISO2": "AN",
    "ISO3": "ANT",
    "countryName": "Netherlands Antilles",
    "phoneCode": "599"
  },
  {
    "ISO2": "NC",
    "ISO3": "NCL",
    "countryName": "New Caledonia",
    "phoneCode": "687"
  },
  {
    "ISO2": "NZ",
    "ISO3": "NZL",
    "countryName": "New Zealand",
    "phoneCode": "64"
  },
  {
    "ISO2": "NI",
    "ISO3": "NIC",
    "countryName": "Nicaragua",
    "phoneCode": "505"
  },
  {
    "ISO2": "NE",
    "ISO3": "NER",
    "countryName": "Niger",
    "phoneCode": "227"
  },
  {
    "ISO2": "NG",
    "ISO3": "NGA",
    "countryName": "Nigeria",
    "phoneCode": "234"
  },
  {
    "ISO2": "NU",
    "ISO3": "NIU",
    "countryName": "Niue",
    "phoneCode": "683"
  },
  {
    "ISO2": "KP",
    "ISO3": "PRK",
    "countryName": "North Korea",
    "phoneCode": "850"
  },
  {
    "ISO2": "MP",
    "ISO3": "MNP",
    "countryName": "Northern Mariana Islands",
    "phoneCode": "1-670"
  },
  {
    "ISO2": "NO",
    "ISO3": "NOR",
    "countryName": "Norway",
    "phoneCode": "47"
  },
  {
    "ISO2": "OM",
    "ISO3": "OMN",
    "countryName": "Oman",
    "phoneCode": "968"
  },
  {
    "ISO2": "PK",
    "ISO3": "PAK",
    "countryName": "Pakistan",
    "phoneCode": "92"
  },
  {
    "ISO2": "PW",
    "ISO3": "PLW",
    "countryName": "Palau",
    "phoneCode": "680"
  },
  {
    "ISO2": "PS",
    "ISO3": "PSE",
    "countryName": "Palestine",
    "phoneCode": "970"
  },
  {
    "ISO2": "PA",
    "ISO3": "PAN",
    "countryName": "Panama",
    "phoneCode": "507"
  },
  {
    "ISO2": "PG",
    "ISO3": "PNG",
    "countryName": "Papua New Guinea",
    "phoneCode": "675"
  },
  {
    "ISO2": "PY",
    "ISO3": "PRY",
    "countryName": "Paraguay",
    "phoneCode": "595"
  },
  {
    "ISO2": "PE",
    "ISO3": "PER",
    "countryName": "Peru",
    "phoneCode": "51"
  },
  {
    "ISO2": "PH",
    "ISO3": "PHL",
    "countryName": "Philippines",
    "phoneCode": "63"
  },
  {
    "ISO2": "PN",
    "ISO3": "PCN",
    "countryName": "Pitcairn",
    "phoneCode": "64"
  },
  {
    "ISO2": "PL",
    "ISO3": "POL",
    "countryName": "Poland",
    "phoneCode": "48"
  },
  {
    "ISO2": "PT",
    "ISO3": "PRT",
    "countryName": "Portugal",
    "phoneCode": "351"
  },
  {
    "ISO2": "PR",
    "ISO3": "PRI",
    "countryName": "Puerto Rico",
    "phoneCode": "1-787, 1-939"
  },
  {
    "ISO2": "QA",
    "ISO3": "QAT",
    "countryName": "Qatar",
    "phoneCode": "974"
  },
  {
    "ISO2": "CG",
    "ISO3": "COG",
    "countryName": "Republic of the Congo",
    "phoneCode": "242"
  },
  {
    "ISO2": "RE",
    "ISO3": "REU",
    "countryName": "Reunion",
    "phoneCode": "262"
  },
  {
    "ISO2": "RO",
    "ISO3": "ROU",
    "countryName": "Romania",
    "phoneCode": "40"
  },
  {
    "ISO2": "RU",
    "ISO3": "RUS",
    "countryName": "Russia",
    "phoneCode": "7"
  },
  {
    "ISO2": "RW",
    "ISO3": "RWA",
    "countryName": "Rwanda",
    "phoneCode": "250"
  },
  {
    "ISO2": "BL",
    "ISO3": "BLM",
    "countryName": "Saint Barthelemy",
    "phoneCode": "590"
  },
  {
    "ISO2": "SH",
    "ISO3": "SHN",
    "countryName": "Saint Helena",
    "phoneCode": "290"
  },
  {
    "ISO2": "KN",
    "ISO3": "KNA",
    "countryName": "Saint Kitts and Nevis",
    "phoneCode": "1-869"
  },
  {
    "ISO2": "LC",
    "ISO3": "LCA",
    "countryName": "Saint Lucia",
    "phoneCode": "1-758"
  },
  {
    "ISO2": "MF",
    "ISO3": "MAF",
    "countryName": "Saint Martin",
    "phoneCode": "590"
  },
  {
    "ISO2": "PM",
    "ISO3": "SPM",
    "countryName": "Saint Pierre and Miquelon",
    "phoneCode": "508"
  },
  {
    "ISO2": "VC",
    "ISO3": "VCT",
    "countryName": "Saint Vincent and the Grenadines",
    "phoneCode": "1-784"
  },
  {
    "ISO2": "WS",
    "ISO3": "WSM",
    "countryName": "Samoa",
    "phoneCode": "685"
  },
  {
    "ISO2": "SM",
    "ISO3": "SMR",
    "countryName": "San Marino",
    "phoneCode": "378"
  },
  {
    "ISO2": "ST",
    "ISO3": "STP",
    "countryName": "Sao Tome and Principe",
    "phoneCode": "239"
  },
  {
    "ISO2": "SA",
    "ISO3": "SAU",
    "countryName": "Saudi Arabia",
    "phoneCode": "966"
  },
  {
    "ISO2": "SN",
    "ISO3": "SEN",
    "countryName": "Senegal",
    "phoneCode": "221"
  },
  {
    "ISO2": "RS",
    "ISO3": "SRB",
    "countryName": "Serbia",
    "phoneCode": "381"
  },
  {
    "ISO2": "SC",
    "ISO3": "SYC",
    "countryName": "Seychelles",
    "phoneCode": "248"
  },
  {
    "ISO2": "SL",
    "ISO3": "SLE",
    "countryName": "Sierra Leone",
    "phoneCode": "232"
  },
  {
    "ISO2": "SG",
    "ISO3": "SGP",
    "countryName": "Singapore",
    "phoneCode": "65"
  },
  {
    "ISO2": "SX",
    "ISO3": "SXM",
    "countryName": "Sint Maarten",
    "phoneCode": "1-721"
  },
  {
    "ISO2": "SK",
    "ISO3": "SVK",
    "countryName": "Slovakia",
    "phoneCode": "421"
  },
  {
    "ISO2": "SI",
    "ISO3": "SVN",
    "countryName": "Slovenia",
    "phoneCode": "386"
  },
  {
    "ISO2": "SB",
    "ISO3": "SLB",
    "countryName": "Solomon Islands",
    "phoneCode": "677"
  },
  {
    "ISO2": "SO",
    "ISO3": "SOM",
    "countryName": "Somalia",
    "phoneCode": "252"
  },
  {
    "ISO2": "ZA",
    "ISO3": "ZAF",
    "countryName": "South Africa",
    "phoneCode": "27"
  },
  {
    "ISO2": "KR",
    "ISO3": "KOR",
    "countryName": "South Korea",
    "phoneCode": "82"
  },
  {
    "ISO2": "SS",
    "ISO3": "SSD",
    "countryName": "South Sudan",
    "phoneCode": "211"
  },
  {
    "ISO2": "ES",
    "ISO3": "ESP",
    "countryName": "Spain",
    "phoneCode": "34"
  },
  {
    "ISO2": "LK",
    "ISO3": "LKA",
    "countryName": "Sri Lanka",
    "phoneCode": "94"
  },
  {
    "ISO2": "SD",
    "ISO3": "SDN",
    "countryName": "Sudan",
    "phoneCode": "249"
  },
  {
    "ISO2": "SR",
    "ISO3": "SUR",
    "countryName": "Suriname",
    "phoneCode": "597"
  },
  {
    "ISO2": "SJ",
    "ISO3": "SJM",
    "countryName": "Svalbard and Jan Mayen",
    "phoneCode": "47"
  },
  {
    "ISO2": "SZ",
    "ISO3": "SWZ",
    "countryName": "Swaziland",
    "phoneCode": "268"
  },
  {
    "ISO2": "SE",
    "ISO3": "SWE",
    "countryName": "Sweden",
    "phoneCode": "46"
  },
  {
    "ISO2": "CH",
    "ISO3": "CHE",
    "countryName": "Switzerland",
    "phoneCode": "41"
  },
  {
    "ISO2": "SY",
    "ISO3": "SYR",
    "countryName": "Syria",
    "phoneCode": "963"
  },
  {
    "ISO2": "TW",
    "ISO3": "TWN",
    "countryName": "Taiwan",
    "phoneCode": "886"
  },
  {
    "ISO2": "TJ",
    "ISO3": "TJK",
    "countryName": "Tajikistan",
    "phoneCode": "992"
  },
  {
    "ISO2": "TZ",
    "ISO3": "TZA",
    "countryName": "Tanzania",
    "phoneCode": "255"
  },
  {
    "ISO2": "TH",
    "ISO3": "THA",
    "countryName": "Thailand",
    "phoneCode": "66"
  },
  {
    "ISO2": "TG",
    "ISO3": "TGO",
    "countryName": "Togo",
    "phoneCode": "228"
  },
  {
    "ISO2": "TK",
    "ISO3": "TKL",
    "countryName": "Tokelau",
    "phoneCode": "690"
  },
  {
    "ISO2": "TO",
    "ISO3": "TON",
    "countryName": "Tonga",
    "phoneCode": "676"
  },
  {
    "ISO2": "TT",
    "ISO3": "TTO",
    "countryName": "Trinidad and Tobago",
    "phoneCode": "1-868"
  },
  {
    "ISO2": "TN",
    "ISO3": "TUN",
    "countryName": "Tunisia",
    "phoneCode": "216"
  },
  {
    "ISO2": "TR",
    "ISO3": "TUR",
    "countryName": "Turkey",
    "phoneCode": "90"
  },
  {
    "ISO2": "TM",
    "ISO3": "TKM",
    "countryName": "Turkmenistan",
    "phoneCode": "993"
  },
  {
    "ISO2": "TC",
    "ISO3": "TCA",
    "countryName": "Turks and Caicos Islands",
    "phoneCode": "1-649"
  },
  {
    "ISO2": "TV",
    "ISO3": "TUV",
    "countryName": "Tuvalu",
    "phoneCode": "688"
  },
  {
    "ISO2": "VI",
    "ISO3": "VIR",
    "countryName": "U.S. Virgin Islands",
    "phoneCode": "1-340"
  },
  {
    "ISO2": "UG",
    "ISO3": "UGA",
    "countryName": "Uganda",
    "phoneCode": "256"
  },
  {
    "ISO2": "UA",
    "ISO3": "UKR",
    "countryName": "Ukraine",
    "phoneCode": "380"
  },
  {
    "ISO2": "AE",
    "ISO3": "ARE",
    "countryName": "United Arab Emirates",
    "phoneCode": "971"
  },
  {
    "ISO2": "GB",
    "ISO3": "GBR",
    "countryName": "United Kingdom",
    "phoneCode": "44"
  },
  {
    "ISO2": "US",
    "ISO3": "USA",
    "countryName": "United States",
    "phoneCode": "1"
  },
  {
    "ISO2": "UY",
    "ISO3": "URY",
    "countryName": "Uruguay",
    "phoneCode": "598"
  },
  {
    "ISO2": "UZ",
    "ISO3": "UZB",
    "countryName": "Uzbekistan",
    "phoneCode": "998"
  },
  {
    "ISO2": "VU",
    "ISO3": "VUT",
    "countryName": "Vanuatu",
    "phoneCode": "678"
  },
  {
    "ISO2": "VA",
    "ISO3": "VAT",
    "countryName": "Vatican",
    "phoneCode": "379"
  },
  {
    "ISO2": "VE",
    "ISO3": "VEN",
    "countryName": "Venezuela",
    "phoneCode": "58"
  },
  {
    "ISO2": "VN",
    "ISO3": "VNM",
    "countryName": "Vietnam",
    "phoneCode": "84"
  },
  {
    "ISO2": "WF",
    "ISO3": "WLF",
    "countryName": "Wallis and Futuna",
    "phoneCode": "681"
  },
  {
    "ISO2": "EH",
    "ISO3": "ESH",
    "countryName": "Western Sahara",
    "phoneCode": "212"
  },
  {
    "ISO2": "YE",
    "ISO3": "YEM",
    "countryName": "Yemen",
    "phoneCode": "967"
  },
  {
    "ISO2": "ZM",
    "ISO3": "ZMB",
    "countryName": "Zambia",
    "phoneCode": "260"
  },
  {
    "ISO2": "ZW",
    "ISO3": "ZWE",
    "countryName": "Zimbabwe",
    "phoneCode": "263"
  }
]

export enum EVENT_STATUS {
  LIVE = 'live',
  UPCOMING = 'upcoming',
  CLOSED = 'closed',
  PROCESSING = 'processing',
}

export enum USERS_ROLES {
  BUYER = 'buyer',
  SELLER = 'seller',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super admin',
  NEW_USER = 'new user'
}

export const USER_EVENT_STATUS = {
  ASSIGNED: 'ASSIGNED_EVENT',
  NOT_ASSIGNED: 'NOT_ASSIGNED_EVENT'
}

export const NOTIFICATIONS = {
  GENERAL: 'general-notification',
  USER: 'user-notification',
  EVENT: 'event-notification',
  COMPANY: 'company-notification'
}

export const USER_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
  ARCHIVED: 'archived',
  SUSPENDED: 'suspended'
}

export const REPORT_TYPES = {
  TENDER_REPORT: 'tender',
  INTERNAL_REPORT: 'internal'
}

export const ONBOARDING_STEPS = {
  COMPANY_STEP: 'company-information',
  ADD_BUYERS_STEP: 'additional-buyers-information',
  REQUIRED_DOCS_STEP:'required-documents',
  BUYERS_AGREEMENT_STEP: 'buyers-agreement'
}