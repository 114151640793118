import { Heading, Text, Stack, StackProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { theme } from '../../theme'

type HeaderProps = StackProps & {
  headerTitle?: string | React.ReactNode
  subHeaderText?: string | React.ReactNode
  children?: ReactNode
}

const HeaderSection: React.FC<HeaderProps> = ({
  headerTitle,
  subHeaderText,
  children,
  ...rest
}) => {
  return (
    <Stack {...rest}>
      <Heading as="h4" textAlign="center">
        {headerTitle}
      </Heading>
      <Text>{subHeaderText}</Text>
      {children}
    </Stack>
  )
}

HeaderSection.defaultProps = {
  w: '100%',
  bg: theme.colors.secondary.background[400],
  direction: 'column',
  align: 'center',
  justifyContent: 'center',
  spacing: 5,
  textColor: theme.colors.white
}
export default HeaderSection
