import React, { Suspense } from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import FillLoader from '../components/FillLoader'
import PageNotFound from '../containers/PageNotFound'
import KoinAdminProtectedRoute from './KoinAdminProtectedRoute'
import KoinClientsProtectedRoute from './KoinClientsProtectedRoute'
import PublicRoute from './PublicRoutes'
import {
  AUTHENTICATED_ADMIN_ROUTES,
  AUTHENTICATED_COMMON_ROUTES,
  AUTHENTICATED_KOIN_CLIENT_ROUTES,
  PUBLIC_ROUTES
} from './routes'
import SharedProtectedRoute from './SharedProtectedRoute'

export type NavItem = {
  to: string
  title: string
  icon: React.ReactNode
  subMenu?: Array<{ to: string; title: string }>
}

const Router = () =>
  useRoutes([
    {
      element: <KoinAdminProtectedRoute />,
      children: AUTHENTICATED_ADMIN_ROUTES.map((route) => ({
        path: route.path,
        element: <route.component />
      }))
    },
    {
      element: <KoinClientsProtectedRoute />,
      children: AUTHENTICATED_KOIN_CLIENT_ROUTES.map((route) => ({
        path: route.path,
        element: <route.component />
      }))
    },
    {
      element: <SharedProtectedRoute />,
      children: AUTHENTICATED_COMMON_ROUTES.map((route) => ({
        path: route.path,
        element: <route.component />
      }))
    },
    {
      element: <PublicRoute />,
      children: PUBLIC_ROUTES.map((route) => ({ path: route.path, element: <route.component /> }))
    },
    {
      element: <PageNotFound />,
      path: '*'
    }
  ])

const Navigation: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<FillLoader mt={10} />}>
        <Router />
      </Suspense>
    </BrowserRouter>
  )
}

export default Navigation
