import { chakra, Stack, Flex, Image, Divider, Text } from '@chakra-ui/react'
import React from 'react'
import { images, theme } from '../../../theme'

const AuthedAdmindFooter: React.FC = () => {
  return (
    <chakra.footer
      w="full"
      px={{ base: 2, sm: 4 }}
      py={4}
      color={theme.colors.white}
      bgImage={images.THeadBakground}
      bgSize={'cover'}
    >
      <Stack p={10} w="100%" align="center">
        <Image w="84px" h="32px" src={images.koinLogo} />
      </Stack>
      <Divider borderWidth="2" />
      <Flex p={10} alignItems="center" justifyContent="space-between">
        <Text fontSize="14px" variant="6" pt="32px" color={theme.colors.white}>
          &copy; {new Date().getFullYear()} KOIN. All rights reserved.
        </Text>
        <Stack spacing={3} direction="row">
          <chakra.a href={''} title={''} display="flex" alignItems="center">
            <Image src={images.twitter} width="24px" />
          </chakra.a>
          <chakra.a href={''} title={''} width="24px" display="flex" alignItems="center">
            <Image src={images.linkedin} />
          </chakra.a>
          <chakra.a href={''} title={''} width="24px" display="flex" alignItems="center">
            <Image src={images.facebook} />
          </chakra.a>
        </Stack>
      </Flex>
    </chakra.footer>
  )
}
export default AuthedAdmindFooter
