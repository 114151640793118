import React from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { theme } from '../../theme'
import { useAuthContext } from '../../context/AuthProvider'
import { hideNavItem } from '../../utils'

type Item = {
  to: string
  title: string
  hideItem?: boolean
}

// eslint-disable-next-line
const privateNavs = (loggedInUser: any): Item[] =>[
  {
    to: '/dashboard',
    title: 'Bidding Home',
    hideItem: hideNavItem('/dashboard', loggedInUser)
  },
  {
    to: '/events',
    title: 'Events',
    hideItem: hideNavItem('/events', loggedInUser)
  },
  {
    to: '/users',
    title: 'Users',
    hideItem: hideNavItem('/users', loggedInUser)
  },
  {
    to: '/companies',
    title: 'Companies',
    hideItem: hideNavItem('/companies', loggedInUser)
  },
  {
    to: '/results',
    title: 'Results',
    hideItem: hideNavItem('/results', loggedInUser)
  },
  {
    to: '/contact-us',
    title: 'Contact Us',
    hideItem: hideNavItem('/contact-us', loggedInUser)
  }
]
const marketingSiteNavs: Item[] = [
  {
    to: '/',
    title: 'Home'
  },
  {
    to: '/about-us',
    title: 'About us'
  },
  {
    to: '/sales-calendar',
    title: 'Sales Calendar'
  },
  {
    to: '/contact-us',
    title: 'Contact Us'
  }
]

const MenuItem = styled(NavLink)`
  color: ${theme.colors.white};
  font-size: 14px;
  line-height: 18px;
  border-radius: none;
  border: 4px solid transparent;
  position: relative;
  text-shadow: 1px 0 0 transparent;

  &:hover,
  &.active {
    background: none;
    text-shadow: 1px 0 0 currentColor, 1px 0 0 currentColor;
    &::after {
      content: '';
      position: absolute;
      bottom: -9px;
      right: 0px;
      left: 0;
      height: 4px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: ${theme.colors.primary[400]};
    }
  }
`

const MainNavItems: React.FC = () => {
  const { isAuthenticated, user } = useAuthContext()
  return (
    <>
      {(isAuthenticated ? privateNavs(user) : marketingSiteNavs).map(({ to, title, hideItem }) => {
        if (!hideItem) {
          return (
            <MenuItem key={to} to={to} data-text={title}>
              {title}
            </MenuItem>
          )
        }
        return null
      })}
    </>
  )
}
export default MainNavItems
