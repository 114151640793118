import Axios from "axios"
import { emitter } from "../components/Alerts"
import { BASE } from "../constants"
import { getAuthenticatedUser } from '../utils'

interface PostPutArgsType {
  url: string,
  // eslint-disable-next-line
  payload: any,
  // eslint-disable-next-line
  callback?: (err: any) => void
}

interface GetDeleteArgsType {
  url: string,
  // eslint-disable-next-line
  options?: any
  // eslint-disable-next-line
  callback?: (err: any) => void
}

const axios = Axios.create({
  baseURL: BASE,
  headers: {
    'Authorization': 'Bearer ',
  },
});

// eslint-disable-next-line
axios.interceptors.request.use((config: any) => {
  const { token } = getAuthenticatedUser();
  config.headers.Authorization = `Bearer ${token}`;
  emitter.emit('close-alert');
  return config;
  // eslint-disable-next-line
}, (err: any) => { 
  console.error('Request Interceptor', err);
  return Promise.reject(err);
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  const errReponse = error.response;
  console.error('Response Interceptor:', errReponse);
  emitter.emit('alert', {type: 'error', error: errReponse});
  return Promise.reject(errReponse);
});

const requestPost = async ({ url, payload, callback } : PostPutArgsType) => {
  const { data, options } = payload
  try {
    const responseData = await axios.post(url, data, {
      ...options,
      baseURL: BASE,
    })
    return { ...responseData?.data, success: responseData.status };
    // eslint-disable-next-line
  } catch(error: any) {
    if (callback) {
      callback(error)
    }
    throw error;
  }
}

const requestPut = async ({ url, payload, callback } : PostPutArgsType) => {
  const { data, options } = payload
  try {
    const responseData = await axios.put(url, data, {
      ...options,
      baseURL: BASE,
    })
    return { ...responseData?.data, success: responseData.status };
    // eslint-disable-next-line
  } catch(error: any) {
    if (callback) {
      callback(error)
    }
    throw error;
  }
}

const requestGet = async ({ url, options, callback } : GetDeleteArgsType) => {
  try {
    const responseData = await axios.get(url, {
      ...options,
      baseURL: BASE
    })
    return { ...responseData?.data, success: responseData.status };
    // eslint-disable-next-line
  } catch(error: any) {
    if (callback) {
      callback(error)
    }
    throw error;
  }
}

const requestDelete = async ({ url, options, callback } : GetDeleteArgsType) => {
  try {
    const responseData = await axios.delete(url, {
      ...options,
      baseURL: BASE,
    })
    return { ...responseData?.data, success: responseData.status };
    // eslint-disable-next-line
  } catch(error: any) {
    if (callback) {
      callback(error)
    }
    throw error;
  }
}

export {
  requestPost,
  requestPut,
  requestGet,
  requestDelete,
}