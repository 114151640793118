import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import React from 'react'
import 'react-dates/initialize'
import ReactDOM from 'react-dom/client'
import App from './App'
import { APP_NAME } from './constants'
import { AppProvider, AuthProvider } from './context'
import * as serviceWorker from './serviceWorker'
import { theme } from './theme'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const root = ReactDOM.createRoot(document.getElementById("root") as Element)
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeProvider>
        <AuthProvider key="session">
          <HelmetProvider>
            <Helmet titleTemplate={`${APP_NAME} | %s`} />
            <AppProvider>
              <Global
                styles={css`
                  * {
                    font-family: ${theme.fonts.body};
                  }
                `}
              />
              <App />
            </AppProvider>
          </HelmetProvider>
        </AuthProvider>
      </ColorModeProvider>
    </ChakraProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
