export default {
  bg: require('../assets/images/core-bg.jpg'),
  koinLogo: require('../assets/images/koin-logo-blue.png'),
  facebook: require('../assets/images/facebook.png'),
  twitter: require('../assets/images/twitter.png'),
  linkedin: require('../assets/images/linkedin.png'),
  koinLogoWhite: require('../assets/images/koin-logo-white.png'),
  404: require('../assets/images/404.png'),
  noData: require('../assets/images/no-data.png'),
  koinBackground: require('../assets/images/placeholder-koin-background.png'),
  mail_icon: require('../assets/images/mail_icon.png'),
  password_icon: require('../assets/images/password_icon.png'),
  check_icon: require('../assets/images/check_icon.png'),
  notificationIcon: require('../assets/images/notifications-icon.png'),
  bellIcon: require('../assets/images/bell.png'),
  checks: require('../assets/images/checks.png'),
  upload: require('../assets/images/upload.png'),
  bids_icon: require('../assets/images/request-icon.svg'),
  diamond: require('../assets/images/diamond_image.png'),
  adam: require('../assets/images/adam.png'),
  blueDude: require('../assets/images/blue_dude.png'),
  sunlightDude: require('../assets/images/sunlight_dude.png'),
  documentIcon: require('../assets/images/document.png'),
  currentStep: require('../assets/images/currentStep.png'),
  completeStep: require('../assets/images/completeStep.png'),
  nextStep: require('../assets/images/nextStep.png'),
  login_backgound: require('../assets/images/Login-Background.png'),
  loginBgRight: require('../assets/images/loginBgRight.png'),
  headerBackground: require('../assets/images/header_background.png'),
  modalBackground: require('../assets/images/modal_Background.png'),
  flowBackground: require('../assets/images/flowBackground.png'),
  THeadBakground: require('../assets/images/TableBg.png'),
  loginImage: require('../assets/images/LoginImage.png'),
  iphone_macbook: require('../assets/images/iphone-macbook.png')
}