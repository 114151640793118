import { Button, Flex } from '@chakra-ui/react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { theme } from '../theme'
import { AppAlert } from '../components/Alerts';
import { useNavigate, } from 'react-router-dom';

export default class PageWrap extends React.Component {

  state = { hasError: false};

  // eslint-disable-next-line
  props: any;

  // eslint-disable-next-line
  static defaultProps: any;

  // eslint-disable-next-line
  constructor(props: any) {
    super(props);
    this.returnHome = this.returnHome.bind(this);
  }

  // eslint-disable-next-line
  static getDerivedStateFromError(error: any) {
    return {hasError: true};
  }

  // eslint-disable-next-line
  componentDidCatch(error: any, errorInfo: any) {
    console.error({boundary: {error, errorInfo}});
  }
  
  returnHome() {
    this.setState({hasError: false});
    const navigate = useNavigate();
    navigate('/');
  }

  render() {  
    if (this.state.hasError) {
      return (
        <div style={{paddingTop: '20vh', paddingBottom: '60vh', textAlign: 'center',}}>
            <h1 style={{fontSize: '28px'}}>Ooooops!!!</h1>
            <h3 style={{fontSize: '22px'}}>Something went wrong!</h3>
            <p>
              <Button onClick={this.returnHome}>Return to home page</Button>
            </p>
        </div>
      )
    }
    const {title, children, ...rest} = this.props;
    return (
      <>
        <AppAlert />
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Flex {...rest}>{children}</Flex>
      </>
    )
  }
}

PageWrap.defaultProps = {
  p: 4,
  flex: 1,
  pt: 'calc(64px + 1rem)',
  bg: theme.colors.secondary.background[400],
  height: '100%',
  flexDir: 'column',
  minHeight: '100vh',
  align: 'flex-start',
  justify: 'flex-start',
  color: theme.colors.white,
}
