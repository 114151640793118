import React from "react"
import { Outlet, useLocation } from "react-router-dom"
import Footer from "../../components/Footer"
import GetStarted from "../../components/Footer/GetStarted"
import MainNavigation from "../../components/Header/MainNavigation"
import { SITE_PAGE_URL } from "../../utils"

const PublicRoute: React.FC = () => {
  const { pathname } = useLocation()

  switch(pathname) {
    case SITE_PAGE_URL.LOGIN_PAGE:
    case SITE_PAGE_URL.SIGN_UP:
    case SITE_PAGE_URL.FORGOT_PASSWORD:
    case SITE_PAGE_URL.CONFIRM_EMAIL:
      return (
        <Outlet />
      )
    default:
      return (
        <>
          <MainNavigation />
          <Outlet />
          <GetStarted />
          <Footer />
        </>
      )
  }
}

export default PublicRoute