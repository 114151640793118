import { Alert, AlertIcon, CloseButton, Box, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IAlert } from './IAlert'
import { emitter } from './emitter'
import { useAuthContext } from '../../context/AuthProvider/index'
import { useNavigate } from 'react-router-dom'
import { renewUserToken } from '../../utils/sessionHelper'
import { theme } from '../../theme'

type Err = {
  msg: string
  param: string
  location: string
}

const AppAlert: React.FC = () => {
  const defaultAlert: IAlert = {
    message: 'Default Message',
    type: 'info',
    error: null,
    display: 'none',
    isTimeout: false
  }
  const [alert, setAlert] = useState(defaultAlert)

  const { logout } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    emitter.on('logout', handleLogout)

    return () => {
      emitter.off('logout', handleLogout)
    }
  }, [])

  const handleLogout = () => {
    logout && logout()
    navigate('/login')
  }

  useEffect(() => {
    emitter.on('alert', activate)
    emitter.on('close-alert', close)

    return () => {
      emitter.off('alert', activate)
      emitter.off('close-alert', close)
    }
  }, [])

  // eslint-disable-next-line
  const activate = (alrt: any) => {
    if (alrt.error) {
      parseError(alrt)
    }
    alrt.display = 'block'
    setAlert(alrt)
  }

  const parseError = (alrt: IAlert) => { 
    const { error } = alrt;
    if (!error) {
      alrt.message = 'The server cannot be reached. Please check your network connection and try again.'
    } else {          
      const data = error?.data || {}; 
      const status =  error?.status; 
      const message = data.message || error.message;
      switch(status) {
        case 400:               
          alrt.message = parse400Error(error);     
          break;
        case 401: 
          if (message?.includes('expire')) {
            alrt.message = 'Your session has expired. You must re-login to continue.'
            setTimeout(() => {
              handleLogout()
            }, 5000)
          } else {
            alrt.message = message || 'Unauthorized access'
          }
          break
        case 403:
          alrt.message = message || 'Access denied'
          break
        default:
          alrt.message = message || error.toString()
      }
      if (typeof alrt.message != 'string') {
        alrt.message = error.statusText || 'An unknown error occured!'
      }
    }
  }

  // eslint-disable-next-line
  const parse400Error = (error: any) => {
    let message = 'Invalid data'
    if (Array.isArray(error?.data.error)) {
      message = ''
      error.data.error.forEach((err: Err) => {
        message += `${err.msg}. `
      })
    } else if (error?.data.message) {
      message = error.data.message
    }
    return message
  }

  const renewToken = () => {
    close()
    renewUserToken().then().catch()
  }

  const close = () => {
    setAlert((alrt: IAlert) => {
      // eslint-disable-next-line
      const alrtCopy: any = { ...alrt }
      alrtCopy.display = 'none'
      alrtCopy.message = ''
      return alrtCopy
    })
  }

  return (
    <Alert
      status={alert.type}
      display={alert.display}
      zIndex={10000}
      position={'fixed'}
      width={'90vw'}
      m={'5vw'}
    >
      <Box textAlign={'right'} mb={-7}>
        {alert.isTimeout ? (
          <Button
            onClick={renewToken}
            backgroundColor={theme.colors.primary[400]}
            color={theme.colors.white}
          >
            Continue
          </Button>
        ) : (
          <CloseButton onClick={close} display={'inline'} />
        )}
      </Box>
      <AlertIcon />
      <Box mt={-6} ml={7}>
        {alert.message}
      </Box>
    </Alert>
  )
}
export default AppAlert
