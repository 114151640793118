import { Box, Text } from "@chakra-ui/react"
import React from "react"
import { theme } from "../../theme"

type ErrorAlertProps = {
  message?: string
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message }) => {
  return message ? (
    <Box
      w="100%"
      p={5}
      borderRadius="2xl"
      bg={theme.colors.red[100]}
    >
      <Text color={theme.colors.red[500]}>{message}</Text>
    </Box>
  ) : null
}
export default ErrorAlert;