import { getAuthenticatedUser, setUserData } from '../utils'
import { emitter } from "../components/Alerts"
import { requestPost } from '../utils/requestHelpers'

const sessionState = {
  seconds: 121,
  countingDown: false,
}

document.getElementById('root')?.addEventListener('mousemove', () => {
  if (sessionState.seconds > 60 && sessionState.seconds < 120 ) {
    renewUserToken();
  }
});

// eslint-disable-next-line
let monitorInterval: any;
const monitorSession = () => { 
  const minutes = 2;
  const timeInterval = minutes*1000*60;
  if (monitorInterval) {
    clearInterval(monitorInterval);
  }
  stopCountdown();

  let data = getAuthenticatedUser();
  let expires = data.expires - minutes;
  data.expires = expires > 0 ? expires : 0;
  setUserData(data);
  monitorInterval = setInterval(() => {
    data = getAuthenticatedUser();
    if (data.expires <= 2) {
      sessionState.countingDown = true;
      startCountdown(data.expires);
      clearInterval(monitorInterval);
    } else {
      expires = data.expires - minutes;
      data.expires = expires > 0 ? expires : 0;
      setUserData(data);
    }
  }, timeInterval)
}

// eslint-disable-next-line
let countDownInterval: any;
const startCountdown = (minutes: number) => {
  let seconds = minutes * 60;
  countDownInterval = setInterval(() => {
    if (seconds <= 60 && sessionState.countingDown) {
      const message = `Exiting in ${seconds} seconds. Figures entered will not be saved. Click "Continue" to continue your session.`;
      emitter.emit('alert', { type: 'warning', message, isTimeout: true});
    }
    seconds--;
    sessionState.seconds = seconds;
    if (seconds <= 0) {
      emitter.emit('logout');
      emitter.emit('close-alert');
      stopCountdown();
    }
  }, 1000);
}

const stopCountdown = () => {
  sessionState.countingDown = false;
  clearInterval(countDownInterval);
}

const parseTokenExpire = (expires: string) => {
  let expireMinutes = 0;
  if (expires.includes('m')) {
    const minute = expires.replace('m', '');
    expireMinutes = parseInt(minute);
  } else if (expires.includes('h')) {
    const hour = expires.replace('h', '');
    expireMinutes = parseInt(hour) * 60; 
  }
  return expireMinutes;
}

const renewUserToken = async () => {
  stopCountdown();
  const user = getAuthenticatedUser();
  if (!user.token) {
    return;
  }
  const url = '/users/renew-token';
  // eslint-disable-next-line
  const responseData: any = await requestPost({
    url,
    payload: {
      data: {}
    }
  });
  const data = getAuthenticatedUser();
  const { expires, token } = responseData;
  if (token) {
    data.expires =  parseTokenExpire(expires);
    data.token = token;
    setUserData(data);
    monitorSession();
    sessionState.seconds = data.expires * 60;
  }
  return responseData;
}

export {
  monitorSession,
  renewUserToken,
  parseTokenExpire,
  stopCountdown
}