import {
  GridItem,
  Grid,
  Center,
  Flex,
  chakra,
  Image,
  Text,
  Heading,
  Stack,
  Divider,
  Link
} from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { images, theme } from '../../theme'
import { SITE_PAGE_URL } from '../../utils'

interface FooterLinkItem {
  title: string
  link: string
}
interface FooterColumn {
  header: string
  items: FooterLinkItem[]
}

const footerData: FooterColumn[] = [
  {
    header: 'Products',
    items: [
      {
        title: 'Join Us',
        link: SITE_PAGE_URL.LOGIN_PAGE
      },
      {
        title: 'Sales Calendar',
        link: SITE_PAGE_URL.SALES_CALENDAR
      }
    ]
  },
  {
    header: 'Company',
    items: [
      {
        title: 'About Us',
        link: SITE_PAGE_URL.ABOUT_US
      },
      {
        title: 'Contact',
        link: SITE_PAGE_URL.CONTACT_US
      }
    ]
  },
  {
    header: 'Resources',
    items: [
      {
        title: 'Contact Us',
        link: SITE_PAGE_URL.CONTACT_US
      },
      {
        title: 'Support',
        link: SITE_PAGE_URL.CONTACT_US
      }
    ]
  },
  {
    header: 'Social',
    items: [
      {
        title: 'Twitter',
        link: 'https://google.com'
      },
      {
        title: 'LinkedIn',
        link: 'https://google.com'
      },
      {
        title: 'Facebook',
        link: 'https://google.com'
      }
    ]
  },
  {
    header: 'Legal',
    items: [
      {
        title: 'Terms',
        link: process.env.PUBLIC_URL + '/terms/koin-international-terms.pdf'
      },
      {
        title: 'Privacy',
        link: 'https://google.com'
      },
      {
        title: 'Buyer Agreement',
        link: process.env.PUBLIC_URL + '/terms/buyer-agreement.pdf'
      }
    ]
  }
]

const Footer: React.FC = () => {
  return (
    <chakra.footer
      w="full"
      px={{ base: 2, sm: 4 }}
      py={4}
      color={theme.colors.white}
      bgImage={images.headerBackground}
      // bg={theme.colors.secondary.background[400]}
      bgSize ='cover'
    >
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)', lg: 'repeat(7, 1fr)' }}
        gap={6}
        py={{ base: '32px', md: '48px', lg: '64px' }}
      >
        <GridItem colSpan={[1, 2]} w="100%">
          <Flex direction="column">
            <Center w="100px">
              <chakra.a href="/" title="" display="flex" alignItems="center" mb={4}>
                <Image src={images.koinLogo} />
              </chakra.a>
            </Center>
            <Text color={theme.colors.white} maxWidth="280px">
              Design amazing digital experiences that create more happy in the world.
            </Text>
          </Flex>
        </GridItem>
        {footerData.map((column) => (
          <GridItem w="100%" key={column.header}>
            <Flex direction="column">
              <Heading fontSize="14px" variant="6" mb="16px">
                {column.header}
              </Heading>
              <Stack direction="column" spacing="12px">
                {column.items.map(({ title, link }) => {
                  if (title === 'Terms') {
                    return (
                      <Link
                        key={title}
                        href={process.env.PUBLIC_URL + '/terms/koin-international-terms.pdf'}
                        target="_blank"
                        rel="noreferrer"
                        isExternal
                        _hover={{ color: 'none' }}
                      >
                        <Text fontSize="14px" variant="6">
                          {title}
                        </Text>
                      </Link>
                    )
                  } else if (title === 'Buyer Agreement') {
                    return (
                      <Link
                        key={title}
                        href={process.env.PUBLIC_URL + '/terms/buyer-agreement.pdf'}
                        target="_blank"
                        rel="noreferrer"
                        isExternal
                        _hover={{ color: 'none' }}
                      >
                        <Text fontSize="14px" variant="6">
                          {title}
                        </Text>
                      </Link>
                    )
                  }
                  return (
                    <Link
                      key={title}
                      as={NavLink}
                      to={link}
                      title={title}
                      _hover={{ color: 'none' }}
                    >
                      <Text fontSize="14px" variant="6">
                        {title}
                      </Text>
                    </Link>
                  )
                })}
              </Stack>
            </Flex>
          </GridItem>
        ))}
      </Grid>

      <Divider borderWidth="2" />
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="14px" variant="6" pt="32px" color={theme.colors.white}>
          &copy; {new Date().getFullYear()} KOIN. All rights reserved.
        </Text>
        <Stack spacing={3} direction="row">
          <chakra.a href={''} title={''} display="flex" alignItems="center">
            <Image src={images.twitter} width="24px" />
          </chakra.a>
          <chakra.a href={''} title={''} width="24px" display="flex" alignItems="center">
            <Image src={images.linkedin} />
          </chakra.a>
          <chakra.a href={''} title={''} width="24px" display="flex" alignItems="center">
            <Image src={images.facebook} />
          </chakra.a>
        </Stack>
      </Flex>
    </chakra.footer>
  )
}

export default Footer
