import React from 'react'
import { images, theme } from '../../theme'
import {
  chakra,
  Box,
  Flex,
  VisuallyHidden,
  HStack,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  CloseButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Heading,
  Text,
  MenuDivider,
  MenuGroup,
  MenuItem
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import MainNavItems from './MainNavItems'
import NotificationCard from '../NotificationCard'
import { useAuthContext } from '../../context/AuthProvider'
import { List, User, Bell, Checks } from 'phosphor-react'
import useUserNotifications from '../../hooks/useUserNotifications'
import { Notification } from '../../types'

const NavBtnFilled = styled(Button)``

const MainNavigation: React.FC<{ privateRoutes?: boolean }> = () => {
  const mobileNav = useDisclosure()
  const navigate = useNavigate()
  const { user, logout, isAuthenticated } = useAuthContext()
  const { notifications } = useUserNotifications({ isAuthenticated })

  const handleLogout = () => {
    if (logout) {
      logout()
      navigate('/', { replace: true })
    }
  }

  const logoLink = isAuthenticated ? '/dashboard' : '/'

  return (
    <React.Fragment>
      <chakra.header
        bgImage={images.headerBackground}
        bgSize={'cover'}
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        shadow="md"
        width={{ base: '100%' }}
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <HStack display="flex" spacing={3} alignItems="center">
            <Box display={{ base: 'inline-flex', md: 'none' }}>
              <IconButton
                display={{ base: 'flex', md: 'flex' }}
                aria-label="Open menu"
                fontSize="20px"
                color={theme.colors.primary[400]}
                variant="ghost"
                icon={<List size={24} />}
                onClick={mobileNav.onOpen}
              />

              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? 'flex' : 'none'}
                flexDirection="column"
                p={2}
                pb={4}
                m={2}
                bg={theme.colors.nav.bg}
                spacing={3}
                rounded="sm"
                shadow="sm"
              >
                <CloseButton
                  aria-label="Close menu"
                  justifySelf="self-start"
                  onClick={mobileNav.onClose}
                />
                <MainNavItems />
              </VStack>
            </Box>
            <chakra.a
              href={logoLink}
              title="Choc Home Page"
              display="flex"
              alignItems="center"
              pr="40px"
            >
              <Image src={images.koinLogo} height="32px" width="84px" />
              <VisuallyHidden>Koin International</VisuallyHidden>
            </chakra.a>
            <HStack display={{ base: 'none', md: 'inline-flex' }}>
              <MainNavItems />
            </HStack>
          </HStack>
          <HStack spacing={3} display={{ base: 'inline-flex', md: 'inline-flex' }}>
            {isAuthenticated ? (
              <>
                <Menu closeOnSelect>
                  <MenuButton as={'button'}>
                    <Bell size={24} color={theme.colors.white} />
                  </MenuButton>
                  <MenuList
                    padding={'24px'}
                    borderRadius={'12px 0px 12px 12px'}
                    width={{ base: '65%', md: '100%', lg: '100%' }}
                    bgColor={theme.colors.secondary.background[400]}
                    textColor={theme.colors.white}
                  >
                    <HStack justifyContent={'space-between'}>
                      <Heading fontSize={'20px'} width={'308px'} fontWeight={500}>
                        Unread Notifications
                      </Heading>
                      <Button bgColor={'#113A63'}>
                        <Checks size={12} color={'#8AAAD9'} />
                        <Text fontSize={'16px'} fontWeight={500} color={'#8AAAD9'}>
                          Mark all as read
                        </Text>
                      </Button>
                    </HStack>
                    <Text fontSize={'12px'} color={theme.colors.white} fontWeight={500}>
                      Today
                    </Text>
                    <MenuDivider color={theme.colors.gray[300]} />
                    {(notifications || [])
                      ?.slice(0, 3)
                      .map((notification: Notification) => (
                        <NotificationCard
                          key={notification.id}
                          notification={notification}
                        />
                    ))}
                    <Button
                      width={'100%'}
                      background={theme.colors.primary[400]}
                      textColor={theme.colors.white}
                      onClick={() =>
                        navigate(
                          generatePath('/notifications/:notification', { notification: 'all' })
                        )
                      }
                      fontSize={'16px'}
                      fontWeight={500}
                      marginTop={'20px'}
                    >
                      View All Notifications
                    </Button>
                  </MenuList>
                </Menu>
                <Menu closeOnSelect>
                  <MenuButton right="settings" as={'button'} color="brand">
                    <User size={24} color={theme.colors.white} />
                  </MenuButton>
                  <MenuList
                    mr={3}
                    mt={3}
                    bgColor={theme.colors.secondary.background[400]}
                    textColor={theme.colors.white}
                  >
                    <MenuGroup title="Account">
                      <MenuItem
                        _hover={{ backgroundColor: theme.colors.primary[400] }}
                        onClick={() => navigate('/dashboard')}
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        _hover={{ backgroundColor: theme.colors.primary[400] }}
                        onClick={() =>
                          navigate(generatePath('/profile/:userId', { userId: user.id }))
                        }
                      >
                        Profile
                      </MenuItem>
                      <MenuItem
                        _hover={{ backgroundColor: theme.colors.primary[400] }}
                        onClick={handleLogout}
                      >
                        Logout
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </>
            ) : (
              <HStack display={{ base: 'none', md: 'inline-flex' }}>
                <NavBtnFilled
                  to="/signup"
                  as={Link}
                  bg={theme.colors.primary[400]}
                  variant="outline"
                  size="md"
                  color={theme.colors.white}
                >
                  Sign up
                </NavBtnFilled>
                <Button
                  to="/login"
                  as={Link}
                  variant="outline"
                  size="md"
                  bg={'#8AAAD9'}
                  color={theme.colors.white}
                >
                  Login
                </Button>
              </HStack>
            )}
          </HStack>
        </Flex>
      </chakra.header>
    </React.Fragment>
  )
}
export default MainNavigation
