import { theme as chakraTheme } from '@chakra-ui/react'
import images from './images'

const theme = {
  ...chakraTheme,
  fonts: {
    body: 'Muli, sans-serif',
    mono: 'Muli, sans-serif',
    heading: 'Muli, sans-serif',
    text: 'Poppins, sans-serif'
  },
  filters:{
    50: '#8AAAD9',
    100: '#113A63',
    200: '#D0E3FF'
  },
  theadColor: {
    50: '#263C5D'
  },
  colors: {
    ...chakraTheme.colors,
    nav: {
      bg: '#F4F4F4',
      color: '#30353D',
      btnFilledBg: '#575757',
      btnFilledColor: '#F6F8FB'
    },
    // primary: '#575757',
    // secondary: '#868686',
    background: '#E5E5E5',
    tertiary2: '#F4F4F4',
    accordionBgColor: {
      50: '#2B384F'
    },
    hover: {
      50: '#98A2B3'
    },
    koinDarkBlue: {
      50: '#F2F4F7',
      300: '#CBD5E0',
      800: '#1A202C',
      900: '#101828'
    },
    brand: {
      50: '#a6a6a6',
      100: '#8c8c8c',
      200: '#737373',
      300: '#595959',
      400: '#333333',
      500: '#222222',
      600: '#201418',
      700: '#201418',
      800: '#201418',
      900: '#201418'
    },
    accent: {
      50: '#fff7db',
      100: '#ffe5ae',
      200: '#fdd57f',
      300: '#fbc44e',
      400: '#fab31f',
      500: '#FAB423',
      600: '#af7700',
      700: '#7e5500',
      800: '#4c3300',
      900: '#1d1000'
    },
    success: {
      50: '#e3fbee',
      100: '#c3ebd7',
      200: '#a0dcbf',
      300: '#7ccda7',
      400: '#59bf8e',
      500: '#40a674',
      600: '#30815a',
      700: '#205c40',
      800: '#0e3825',
      900: '#001509',
      1000: '#D1FADF'
    },
    gray: {
      ...chakraTheme.colors.gray,
      2: '#7C7C7C',
      50: '#F9FAFB',
      100: '#E4E4E4',
      200: '#BABABA',
      300: '#CCCCCC',
      400: '#4F4F4F',
      500: '#828282'
    },
    blueGray: {
      50: "#F8F9FC",
      700: '#363F72'
    },
    warning: {
      50: "#FFFAEB",
      100: "#FEF0C7",
      400: "#FDB022",
      700: "#B54708"
    },
    tertiary: {
      50: '#cacaca',
      100: '#f4f4f4'
    },
    textLight: {
      50: '#f6f8fb'
    },
    textDark: {
      50: '#30353d'
    },
    ellipse: {
      50: "#C4C4C4"
    },
    status: {
      live: '#12b76a',
      upcoming: '#344054',
      closed: '#f04438',
      processing: '#475467'
    },
    statusColor: {
      processing: '#FFEAD5',
      open: '#ECFDF3',
      futureBg: '#E0F2FE',
      futureColor: '#026AA2'
    },
    processing: '#FFF6ED',
    border: '#D0D5DD',
    iconColor: '#039855',
    icon: '#667085',
    boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.4)',
    gridGutter: 1,
    primary: {
      25: '#F8E0D1',
      50: '#F4CBB3',
      100: '#EEB18C',
      200: '#E89766',
      300: '#E37D40',
      400: '#DD631A',
      500: '#B85216',
      600: '#934211',
      700: '#6F310D',
      800: '#4A2109',
      900: '#2C1405',

      Gray: {
        25: '#FCFCFD',
        50: '#F9FAFB',
        100: '#F2F4F7',
        200: '#E4E7EC',
        300: '#D0D5DD',
        400: '#98A2B3',
        500: '#667085',
        600: '#475467',
        700: '#344054',
        800: '#1D2939',
        900: '#101828'
      },
      error: {
        25: '#FFFBFA',
        50: '#FEF3F2',
        100: '#FEE4E2',
        200: '#FECDCA',
        300: '#FDA29B',
        400: '#F97066',
        500: '#F04438',
        600: '#D92D20',
        700: '#B42318',
        800: '#912018',
        900: '#7A271A'
      },
      warning: {
        25: '#FFFCF5',
        50: '#FFFAEB',
        100: '#FEF0C7',
        200: '#FEDF89',
        300: '#FEC84B',
        400: '#FDB022',
        500: '#F79009',
        600: '#DC6803',
        700: '#B54708',
        800: '#93370D',
        900: '#7A2E0E'
      },
      success: {
        25: '#F6FEF9',
        50: '#ECFDF3',
        100: '#D1FADF',
        200: '#A6F4C5',
        300: '#6CE9A6',
        400: '#32D583',
        500: '#12B76A',
        600: '#039855',
        700: '#027A48',
        800: '#05603A',
        900: '#054F31'
      }
    },
    secondary: {
      25: '#D2E1F0',
      50: '#B4CDE5',
      100: '#8FB4D9',
      200: '#6A9BCC',
      300: '#4482BF',
      400: '#1F69B2',
      500: '#1A5794',
      600: '#154677',
      700: '#103559',
      800: '#0A233B',
      900: '#061524',
      accent: {
        25: '#FCFCFD',
        50: '#F8F9FC',
        100: '#EAECF5',
        200: '#C8CCE5',
        300: '#9EA5D1',
        400: '#717BBC',
        500: '#4E5BA6',
        600: '#3E4784',
        700: '#363F72',
        800: '#293056',
        900: '#101323'
      },
      text: {
        25: '#FEFEFF',
        50: '#FEFEFF',
        100: '#FEFEFF',
        200: '#FEFEFF',
        300: '#FEFEFF',
        400: '#EEF0F1',
        500: '#D0D2D5',
        600: '#A6A8AA',
        700: '#7D7E80',
        800: '#535455',
        900: '#323233'
      },
      background: {
        25: '#D2D4D8',
        50: '#B4B8BE',
        100: '#8E949E',
        200: '#69707D',
        300: '#434D5D',
        400: '#1E293C',
        500: '#192232',
        600: '#141B28',
        700: '#0F141E',
        800: '#0A0E14',
        900: '#06080C'
      },
      icons: {
        25: '#E8EEF7',
        50: '#D8E3F2',
        100: '#C4D5EC',
        200: '#B1C6E6',
        300: '#9EB8DF',
        400: '#9AAAD9',
        500: '#738EB5',
        600: '#5C7191',
        700: '#45556D',
        800: '#2E3948',
        900: '#1C222B'
      }
    }
  } // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
}

export { theme, images }
