import React from 'react'
import { API_USER_STORAGE_KEY } from '../../constants'
import { useBrowserStorage } from '../../hooks'
import { getAuthenticatedUser } from '../../utils'
import { parseTokenExpire } from '../../utils/sessionHelper'
import authHelpers from '../../utils/authHelpers'
import { requestPost } from '../../utils/requestHelpers'

type AuthProviderProps = {
  logout: () => void
  isAuthenticated: boolean
  register: (
    firstname: string,
    lastname: string,
    phoneNumber: string,
    email: string,
    password: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmEmail: (otp: string) => Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login: (email: string, password: string, rememberMe: boolean) => Promise<any>
  // eslint-disable-next-line
  user: any
  token: string
  // eslint-disable-next-line
  setUser: React.Dispatch<React.SetStateAction<any | undefined>>
}

const AuthContext = React.createContext<Partial<AuthProviderProps>>({})

export const useAuthContext = () => React.useContext(AuthContext)

// eslint-disable-next-line
type UserStorage = any | null

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // eslint-disable-next-line
  const [localUser, setLocalUser, removeLocalUser] = useBrowserStorage<UserStorage>(
    API_USER_STORAGE_KEY,
    'local'
  )

  // eslint-disable-next-line
  const [sessionUser, setSessionUser, removeSessionUser] = useBrowserStorage<UserStorage>(
    API_USER_STORAGE_KEY,
    'session'
  )

  const { isAuthenticated, user, token } = getAuthenticatedUser()

  // eslint-disable-next-line
  const persistUser = (data: any, rememberMe?: boolean) => {
    rememberMe ? setLocalUser(data) : setSessionUser(data)
  }

  const logout = () => {
    removeLocalUser()
    removeSessionUser()
  }

  const login = async (email: string, password: string, rememberMe: boolean) => {
    try {
      const { data } = await requestPost({
        url: '/users/login',
        payload: {
          data: {
            email,
            password
          }
        }
      })

      if (data) {
        persistUser({
          token: data.token,
          expires: parseTokenExpire(data.expires || '')
        }, rememberMe)
      }
    } catch (error) {
      throw error
    }
  }

  const confirmEmail = async (otp: string) => {
    try {
      const { data } = await authHelpers.verifyEmail(otp)
      persistUser(data)
      return data
    } catch (error) {
      throw error
    }
  }

  const register = async (
    firstname: string,
    lastname: string,
    phoneNumber: string,
    email: string,
    password: string
  ) => {
    try {
      const { data } = await authHelpers.register(firstname, lastname, phoneNumber, email, password)
      persistUser(data, true)
      return data
    } catch (error) {
      throw error
    }
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        confirmEmail,
        logout,
        isAuthenticated,
        user,
        token
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
