import { Button, Flex, Image } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PageWrap } from '../../layouts'
import { images, theme } from '../../theme'
import { H4, Text } from '../../typography'

const PageNotFound: React.FC = () => {
  const navigate = useNavigate()
  return (
    <PageWrap
      title="404"
      justify="center"
      align="center"
      height="100vh"
      width="100vw"
      textColor={theme.colors.white}
    >
      <Flex justify="center" align="center" p={4} flexDir="column" textColor={theme.colors.white}>
        <Image mb={6} src={images[404]} width="350px" maxWidth="100%" height="auto" />
        <H4>Sorry, we couldn&apos;t find what you were looking for.</H4>
        <Button onClick={() => navigate('/')} mt={6} color={theme.colors.white} bgColor={theme.colors.primary[400]}>
          <Text fontWeight="lighter">Back Home</Text>
        </Button>
      </Flex>
    </PageWrap>
  )
}

export default PageNotFound
