import React, { useEffect } from 'react'
import Navigation from './navigation'
import { monitorSession } from './utils/sessionHelper'

const App: React.FC = () => {

  useEffect(() => {
    monitorSession();
  }, []);

  return (
    <Navigation />
  )
}

export default App
