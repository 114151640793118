import { Box, Text } from "@chakra-ui/react"
import React from "react"
import { theme } from "../../theme"

type SuccessAlertProps = {
  message?: string
}

const SuccessAlert: React.FC<SuccessAlertProps> = ({ message }) => {
  return message ? (
    <Box
      w="100%"
      p={5}
      borderRadius="2xl"
      bg={theme.colors.green[100]}
    >
      <Text color={theme.colors.green[500]}>{message}</Text>
    </Box>
  ) : null
}
export default SuccessAlert;