import React from 'react'
import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react'
import { theme } from '../../theme'
import externalArrow from '../../assets/images/external-arrow.svg'

type TeamMemberProps = {
  fullname: string
  role: string
  description: string
  photoSrc: string
}

const TeamMember: React.FC<TeamMemberProps> = ({ fullname, role, description, photoSrc }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      w={['350px', '384px']}
      h="480px"
      bgImg={photoSrc}
      alignItems="center"
      justifyContent="end"
      px="20px"
      py="24px"
      bgRepeat="no-repeat"
    >
      <Box
        blur="3xl"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        color={theme.colors.white}
        bg="rgba(255, 255, 255, 0.3)"
        borderRadius="2xl"
        border={`1px ${theme.colors.white} solid`}
        w="336px"
        h="182px"
        px="20px"
        py="24px"
      >
        <HStack w="100%" justify="space-between">
          <Text fontSize="30px" lineHeight="38px" fontWeight={600}>
            {fullname}
          </Text>
          <Image src={externalArrow} />
        </HStack>
        <Stack>
          <Text fontSize="18px" lineHeight="24px" fontWeight={600}>
            {role}
          </Text>
          <Text fontSize="16px" lineHeight="24px" fontWeight={400}>
            {description}
          </Text>
        </Stack>
      </Box>
    </Box>
  )
}
export default TeamMember
