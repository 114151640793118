import React from 'react'
import { generatePath, Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import AuthedAdmindFooter from '../../components/Footer/AuthedFooter'
import MainNavigation from '../../components/Header/MainNavigation'
import { ONBOARDING_STEPS, USERS_ROLES } from '../../constants'
import { useAuthContext } from '../../context/AuthProvider'
import { hasRoles } from '../../utils'

const KoinAdminProtectedRoute: React.FC = () => {
  const location = useLocation()
  const { isAuthenticated, user } = useAuthContext()
  const { eventId, lotId } = useParams()

  if (!isAuthenticated || !hasRoles(user, Object.values(USERS_ROLES))) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }
  if (isAuthenticated) {
    if (hasRoles(user, [USERS_ROLES.BUYER, USERS_ROLES.SELLER])) {
      return <Navigate to="/dashboard" replace state={{ from: location }} />
    } else if (hasRoles(user, [USERS_ROLES.NEW_USER])) {
      return (
        <Navigate
          to={
            generatePath(
              "/onboarding/:userId/add/:information", {
                userId: user.id,
                information: ONBOARDING_STEPS.COMPANY_STEP,
              }
            )
          }
          replace
          state={{ from: location }}
        />
      )
    }
  }

  if (eventId && lotId) {
    if (
      location.pathname === generatePath('/events/:eventId/view/:lotId/bids', { eventId, lotId })
    ) {
      return (
        <>
          <Outlet />
          <AuthedAdmindFooter />
        </>
      )
    }
  }

  return (
    <>
      <MainNavigation />
      <Outlet />
      <AuthedAdmindFooter />
    </>
  )
}
export default KoinAdminProtectedRoute
