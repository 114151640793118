import { lazy } from 'react'
const Users = lazy(() => import('../containers/Users'))
const Signup = lazy(() => import('../containers/Signup'))
const User = lazy(() => import('../containers/Users/User'))
const Companies = lazy(() => import('../containers/Companies'))
const Company = lazy(() => import('../containers/Companies/Company'))
const Profile = lazy(() => import('../containers/Profile'))
const Dashboard = lazy(() => import('../containers/Dashboard/'))
const Login = lazy(() => import('../containers/Login'))
const ConfirmEmail = lazy(() => import('../containers/ConfirmEmail'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const ConfirmReset = lazy(() => import('../containers/ConfirmReset'))
const AboutUs = lazy(() => import('../containers/AboutUs'))
const Home = lazy(() => import('../containers/Home'))
const EventResult = lazy(() => import('../containers/Dashboard/EventResult'))
const EventResults = lazy(() => import('../containers/Dashboard/EventResults'))
const SalesCalendar = lazy(() => import('../containers/SalesCalendar'))
const ContactUs = lazy(() => import('../containers/ContactUs'))
const Notifications = lazy(() => import('../containers/Notifications'))
const Onboarding = lazy(() => import('../containers/Onboarding'))
const Events = lazy(() => import('../containers/Events'))
const Event = lazy(() => import('../containers/Events/Event'))
const EventLotBids = lazy(() => import('../containers/Events/Event/EventLotBids'))
const ViewCompanyDocument = lazy(() => import('../containers/ViewCompanyDocument'))

// Any page routes meant to be seen by admins gets added here
const AUTHENTICATED_ADMIN_ROUTES = [
  {
    exact: true,
    title: 'Events',
    path: '/events',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/edit/information/:information',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:listing/:eventId/edit/information/:information',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/status/:status',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:listing/:eventId/status/:status',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/view',
    component: Event
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/edit/:information',
    component: Event
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/edit/action/:action',
    component: Event
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/edit/status/:status',
    component: Event
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/edit/:lotId/lot',
    component: Event
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/view/:lotId/bids',
    component: EventLotBids
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/edit/:lotId/lot/:action',
    component: Event
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/event/:eventId/view/:lots',
    component: Event
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/event/:eventId/edit/:lots/lot/:lotId',
    component: Event
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/event/:eventId/view/all',
    component: Event
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:eventId/:action',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:listing',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:listing/event/:eventId/:action',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:info/new',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/events/:info/new/:eventId',
    component: Events
  },
  {
    exact: true,
    title: 'Users',
    path: '/users',
    component: Users
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:info/new/:role',
    component: Users
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:info/new/:role/:userId',
    component: Users
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:userInfo/new/:role/:userId/company/:info/new',
    component: Users
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:userInfo/new/:role/:userId/company/:info/new/:companyId',
    component: Users
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:listing',
    component: Users
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:listing/:userId/edit/:status',
    component: Users
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:listing/:userId/edit/information/:information',
    component: Users
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:userId/view',
    component: User
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:userId/edit/',
    component: User
  },
  {
    exact: true,
    title: 'Users',
    path: '/users/:userId/edit/:status',
    component: User
  },
  {
    exact: true,
    title: 'Users Document',
    path: '/users/document/:userId',
    component: ViewCompanyDocument
  },
  {
    exact: true,
    title: 'Companies',
    path: '/companies',
    component: Companies
  },
  {
    exact: true,
    title: 'Companies',
    path: '/companies/:companyId/edit/information/:information',
    component: Companies
  },
  {
    exact: true,
    title: 'Company',
    path: '/companies/:info/new',
    component: Companies
  },
  {
    exact: true,
    title: 'Company',
    path: '/companies/:info/new/:companyId',
    component: Companies
  },
  {
    exact: true,
    title: 'Company',
    path: '/companies/:companyId/view',
    component: Company
  },
  {
    exact: true,
    title: 'Company',
    path: '/companies/:companyId/edit',
    component: Company
  },
  {
    exact: true,
    title: 'Company',
    path: '/companies/:companyId/edit/:status/:userId',
    component: Company
  },
  {
    exact: true,
    title: 'Company Document',
    path: '/companies/document/:companyId',
    component: ViewCompanyDocument
  }
]

// Any page routes meant to be seen by Koin clients(Koin customers) gets added here
const AUTHENTICATED_KOIN_CLIENT_ROUTES = [
  {
    exact: true,
    title: 'Onboarding',
    path: '/onboarding/:userId/add/:information',
    component: Onboarding
  },
  {
    exact: true,
    title: 'Dashboard',
    path: '/dashboard',
    component: Dashboard
  },
  {
    exact: true,
    title: 'Dashboard',
    path: '/dashboard/:eventId/bidding',
    component: Dashboard
  },
  {
    exact: true,
    title: 'Dashboard',
    path: '/dashboard/:eventId/bidding/:lotId/lot',
    component: Dashboard
  },
  {
    exact: true,
    title: 'Dashboard',
    path: '/dashboard/:eventId/request-viewing',
    component: Dashboard
  },
  {
    exact: true,
    title: 'Dashboard',
    path: '/dashboard/:eventId/bidding/:lotId/request-viewing',
    component: Dashboard
  },
  {
    exact: true,
    title: 'Results',
    path: '/results',
    component: EventResults
  },
  {
    exact: true,
    title: 'Results',
    path: '/results/:eventId',
    component: EventResult
  }
]

// Any page routes meant to be seen based only on just verified identification, gets added here
const AUTHENTICATED_COMMON_ROUTES = [
  {
    exact: false,
    title: 'Notifications',
    path: '/notifications',
    component: Notifications
  },
  {
    exact: false,
    title: 'Notifications',
    path: '/notifications/:notification',
    component: Notifications
  },
  {
    exact: false,
    title: 'Notifications',
    path: '/notifications/:notification',
    component: Notifications
  },
  {
    title: 'My Profile',
    path: '/profile/:userId',
    exact: false,
    component: Profile
  },
  {
    title: 'My Profile',
    path: '/profile/:userId/edit',
    exact: false,
    component: Profile
  },
  {
    exact: true,
    title: 'Events',
    path: '/dashboard/events/',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/dashboard/events/:eventId/assign-buyers',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/dashboard/events/:listing',
    component: Events
  },
  {
    exact: true,
    title: 'Events',
    path: '/dashboard/events/:listing/:eventId/assign-buyers',
    component: Events
  }
]

// Any page routes that are accessible by anyone who is not registered with koin, gets added here.
const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/login',
    component: Login
  },
  {
    exact: true,
    title: 'Login',
    path: '/login/:otp',
    component: Login
  },
  {
    exact: true,
    title: 'Confirm Email',
    path: '/confirm-email',
    component: ConfirmEmail
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    exact: true,
    title: 'Reset Confirmed',
    path: '/reset-confirmed',
    component: ConfirmReset
  },
  {
    exact: true,
    title: 'Home',
    path: '/',
    component: Home
  },
  {
    exact: true,
    title: 'Home',
    path: '/:eventId/request-viewing',
    component: Home
  },
  {
    exact: true,
    title: 'About us',
    path: '/about-us',
    component: AboutUs
  },
  {
    exact: true,
    title: 'About us',
    path: '/about-us/:eventId/request-viewing',
    component: AboutUs
  },
  {
    title: 'Sales calendar',
    path: '/sales-calendar',
    component: SalesCalendar
  },
  {
    title: 'Sales calendar',
    path: '/sales-calendar/:eventId/request-viewing',
    component: SalesCalendar
  },
  {
    exact: true,
    title: 'Sales calendar',
    path: '/sales-calendar/:path',
    component: SalesCalendar
  },
  {
    title: 'Sales calendar',
    path: '/sales-calendar/:path/request-viewing/:eventId',
    component: SalesCalendar
  },
  {
    exact: true,
    title: 'Contact Us',
    path: '/contact-us',
    component: ContactUs
  },
  {
    exact: true,
    title: 'Signup',
    path: '/signup',
    component: Signup
  }
]
export {
  AUTHENTICATED_ADMIN_ROUTES,
  AUTHENTICATED_KOIN_CLIENT_ROUTES,
  AUTHENTICATED_COMMON_ROUTES,
  PUBLIC_ROUTES
}
