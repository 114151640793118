import { format } from 'date-fns'
import get from 'lodash/get'
import jwtDecode from 'jwt-decode'
import { isEmpty } from 'lodash'
import { API_USER_STORAGE_KEY, countryISO, EVENT_STATUS, ONBOARDING_STEPS, USERS_ROLES } from '../constants'
import { Bid, Document, Event, Lot, RoleChange, User, Viewing } from '../types'
import { generatePath, useMatch } from 'react-router-dom'
/**
 * Gets JWT of authenticatedUser from either sessionStorage or localStorage
 */
export const fetchJwt = () => {
  const localUser = localStorage.getItem(API_USER_STORAGE_KEY)
  const sessionUser = sessionStorage.getItem(API_USER_STORAGE_KEY)
  const user = sessionUser || localUser

  return user ? JSON.parse(user)?.data?.token : null
}

// eslint-disable-next-line
export function formatError({ response }: any) {
  return get(response, 'data.message[0].messages[0].message', 'An unknown error has occured.')
}

export const sanitizeEvent = (event: Event) => ({
  ...event,
  startDate: new Date(event.startDate),
  endDate: new Date(event.endDate),
  viewings: event?.viewings
    ?.map((eventViewing: Viewing) => ({
      ...eventViewing,
      startDate: new Date(eventViewing.startDate),
      endDate: new Date(eventViewing.endDate)
    }))
    .slice(0, 2)
})

export const getEmptyBuyersProps = (numberOfBuyers: number, numberOfAssociatedBuyers: number) => {
  if (numberOfBuyers === 0 && numberOfAssociatedBuyers === 0) {
    return {
      buyersEmptyProps: {
        title: 'No buyers',
        subTitle: 'Available'
      },
      associatedBuyersEmptyProps: {
        title: 'No buyers',
        subTitle: 'Associated Yet'
      }
    }
  } else if (numberOfBuyers === 0 && numberOfAssociatedBuyers > 0) {
    return {
      buyersEmptyProps: {
        title: 'All buyers',
        subTitle: 'Associated'
      },
      associatedBuyersEmptyProps: {
        title: '',
        subTitle: ''
      }
    }
  } else if (numberOfBuyers > 0 && numberOfAssociatedBuyers === 0) {
    return {
      buyersEmptyProps: {
        title: '',
        subTitle: ''
      },
      associatedBuyersEmptyProps: {
        title: 'No buyers',
        subTitle: 'Associated Yet'
      }
    }
  }
  return {
    buyersEmptyProps: {
      title: '',
      subTitle: ''
    },
    associatedBuyersEmptyProps: {
      title: 'No buyers',
      subTitle: 'Associated Yet'
    }
  }
}

export const formattedDate = (date: string, dateFormat = 'MM/dd/yyyy') => {
  let dateToFormat = new Date()
  if (date) {
    dateToFormat = new Date(date)
  }
  return format(
    new Date(dateToFormat.getFullYear(), dateToFormat.getMonth(), dateToFormat.getDate()),
    dateFormat
  )
}

export const eventFilters = (filter?: string) => {
  switch (filter) {
    case EVENT_STATUS.LIVE:
      return [EVENT_STATUS.LIVE]
    case EVENT_STATUS.UPCOMING:
      return [EVENT_STATUS.UPCOMING]
    case EVENT_STATUS.PROCESSING:
      return [EVENT_STATUS.PROCESSING]
    case EVENT_STATUS.CLOSED:
      return [EVENT_STATUS.CLOSED]
    default:
      return [
        EVENT_STATUS.LIVE,
        EVENT_STATUS.UPCOMING,
        EVENT_STATUS.PROCESSING,
        EVENT_STATUS.CLOSED
      ]
  }
}

export const userRoleFilters = (filter?: string) => {
  switch (filter) {
    case USERS_ROLES.BUYER:
      return [USERS_ROLES.BUYER]
    case USERS_ROLES.SELLER:
      return [USERS_ROLES.SELLER]
    case USERS_ROLES.ADMIN:
      return [USERS_ROLES.ADMIN]
    case USERS_ROLES.SUPER_ADMIN:
      return [USERS_ROLES.SUPER_ADMIN]
    default:
      return [USERS_ROLES.BUYER, USERS_ROLES.SELLER, USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN]
  }
}

export const displayEventStatus = (status?: string) => {
  switch (status) {
    case EVENT_STATUS.LIVE:
      return 'open'
    case EVENT_STATUS.UPCOMING:
      return 'future sales'
    case EVENT_STATUS.PROCESSING:
      return 'processing'
    case EVENT_STATUS.CLOSED:
      return 'closed'
    default:
      return 'all'
  }
}

export const getAuthenticatedUser = () => {
  const localUser = localStorage.getItem(API_USER_STORAGE_KEY)
  try {
    const userData = JSON.parse(localUser || '{}')
    const token = userData?.token
    if (!isEmpty(jwtDecode(token))) {
      // eslint-disable-next-line
      const decodedToken: any = jwtDecode(token)
      if (Object.keys(decodedToken).length > 0) {
        return {
          user: decodedToken.payload.user,
          token,
          expires: userData?.expires,
          isAuthenticated: !isEmpty(decodedToken)
        }
      }
    }
    return {
      user: {},
      token: '',
      expires: 0,
      isAuthenticated: false
    }
  } catch (error) {
    return {
      isAuthenticated: false,
      user: {},
      token: '',
      expires: 0
    }
  }
}

// eslint-disable-next-line
export const setUserData = (userData: any) => {
  const store = localStorage.getItem(API_USER_STORAGE_KEY)
  const local = JSON.parse(store || '{}')
  local.data = userData
  localStorage.setItem(API_USER_STORAGE_KEY, JSON.stringify(local))
}

export const getAuthedUserRoles = (user: User) => {
  if (!isEmpty(user) && user.roleChanges) {
    return {
      roles: user.roleChanges.map((roleChange: RoleChange) => roleChange.role.name)
    }
  }
  return {
    roles: []
  }
}

export const hasRoles = (user: User, roles: string[]): boolean => {
  if (user) {
    const roleChanges = getAuthedUserRoles(user)
    return roles.some((role: string) => roleChanges?.roles?.includes(role))
  }
  return false
}

export const hideNavItem = (item: string, loggedInUser: User): boolean => {
  if (hasRoles(loggedInUser, ['admin'])) {
    switch (item) {
      case SITE_PAGE_URL.DASHBOARD_PAGE:
      case SITE_PAGE_URL.CONTACT_US:
      case SITE_PAGE_URL.RESULTS_PAGE:
        return true
      default:
        return false
    }
  } else if (hasRoles(loggedInUser, ['buyer', 'seller'])) {
    switch (item) {
      case SITE_PAGE_URL.EVENTS_PAGE:
      case SITE_PAGE_URL.USERS_PAGE:
      case SITE_PAGE_URL.COMPANIES_PAGE:
        return true
      default:
        return false
    }
  } else if (hasRoles(loggedInUser, ['new user'])) {
    switch (item) {
      case SITE_PAGE_URL.EVENTS_PAGE:
      case SITE_PAGE_URL.USERS_PAGE:
      case SITE_PAGE_URL.COMPANIES_PAGE:
        return true
      default:
        return false
    }
  }
  return false
}

export const SITE_PAGE_URL = {
  LOGIN_PAGE: '/login',
  SIGN_UP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRM_EMAIL: '/confirm-email',
  SALES_CALENDAR: '/sales-calendar',
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  DASHBOARD_PAGE: '/dashboard',
  RESULTS_PAGE: '/results',
  EVENTS_PAGE: '/events',
  USERS_PAGE: '/users',
  COMPANIES_PAGE: '/companies'
}

export const computeFileSize = (fileSize: number) => {
  if (fileSize / 1000 < 1000) {
    return `${Number(fileSize / 1000).toFixed(2)}KB`
  }
  return `${Number(fileSize / 1000000).toFixed(2)}MB`
}

export const computeTotalEventBidCommitment = (event: Event) => {
  if (event?.bids?.length > 0) {
    return event?.bids?.reduce(
      (prevTotal: number, currentTotal: Bid) => prevTotal + parseFloat(currentTotal?.total),
      0
    )
  }
  return 0
}

export const lotDecison = (decison: string) => {
  switch (decison) {
    case '1st':
      return '1st bid'
    case '2nd':
      return '2nd bid'
    case '3rd':
      return 'WD'
    case '4th':
      return 'UDC'
    default:
      return 'BBV'
  }
}

export const eventLotsNotEditted = (eventLots: Lot[]) =>
  eventLots.filter((lot: Lot) => !lot.decision).length

export const eventLotsEditted = (eventLots: Lot[]) =>
  eventLots.filter((lot: Lot) => lot.decision).length

export const eventLotsProcessed = (eventLots: Lot[]) =>
  eventLots?.length > 0
    ? Number((eventLotsEditted(eventLots) / eventLots.length) * 100).toFixed(2)
    : '0'

export const lotDisplayValue = (eventLot: Lot) => {
  if (eventLot?.decision) {
    switch (eventLot.decision.toLowerCase()) {
      case '1st bid':
      case '2nd bid':
        return eventLot.displayValue
      case 'wd':
      case 'udc':
        return eventLot.decision
      default:
        return eventLot.buyBackValue
    }
  }
  return eventLot.displayValue
}

export const showLotDicison = (eventLot: Lot) => {
  switch (eventLot?.decision?.toLowerCase()) {
    case '1st bid':
      return 'Allocate to 1st Bid'
    case '2nd bid':
      return 'Allocate to 2nd Bid'
    case 'wd':
      return 'Lot withdrawn'
    case 'udc':
      return 'undisclosed'
    default:
      return eventLot.decision
  }
}

export const hasReceivedBids = (lot: Lot) => lot.bids && lot.bids.length > 0

export const canBeAssignedAWinner = (lot: Lot) =>
  lot.decision === '1st bid' || lot.decision === '2nd bid'

export const lotsUserSubmittedBidsFor = (user: User, eventCatalogue: Lot[]) =>
  eventCatalogue.filter(
    (lot: Lot) => !isEmpty(lot.bids?.find((submittedBid: Bid) => submittedBid.user.id === user.id))
  )

export const getUserSubmittedLotBids = (user: User, eventCatalogue: Lot[]) =>
  !isEmpty(eventCatalogue)
    ? Object.fromEntries(
        lotsUserSubmittedBidsFor(user, eventCatalogue)
          .map((lot: Lot) => lot.bids)
          .flat()
          .filter((userBid: Bid | undefined) => userBid && userBid.user.id === user.id)
          .sort((firstBid: Bid | undefined, secondBid: Bid | undefined) => {
            if (firstBid && secondBid) {
              if (parseFloat(firstBid.total) > parseFloat(secondBid.total)) {
                return 1
              }
              if (parseFloat(firstBid.total) < parseFloat(secondBid.total)) {
                return -1
              }
            }
            return 0
          })
          .map((userBid: Bid | undefined) => [userBid?.lot.id, userBid?.total])
      )
    : {}

export const sortEventsByDate = (events: Event[]) => {
  return events.sort((firstEvent: Event, secondEvent: Event) => {
    const firstEventCreatedDate = new Date(firstEvent.createdAt)
    const secondEventCreatedDate = new Date(secondEvent.createdAt)
    if (firstEventCreatedDate > secondEventCreatedDate) {
      return 1
    }
    if (firstEventCreatedDate < secondEventCreatedDate) {
      return -1
    }
    return 0
  })
}

export const sortedEventBids = (eventCatalogue: Lot[], eventBids: Bid[]) => {
  const numberOfEdittedLots = eventCatalogue.filter((lot) => lot.decision).length
  const percentageOfProcessedLots = Number(
    (numberOfEdittedLots / eventCatalogue.length) * 100
  ).toFixed(2)

  if (parseFloat(percentageOfProcessedLots) === 100) {
    return eventBids.sort((firstBid, secondBid) => {
      const firstBidDate = new Date(firstBid.createdAt)
      const secondBidDate = new Date(secondBid.createdAt)
      if (firstBidDate > secondBidDate) {
        return 1
      }
      if (firstBidDate < secondBidDate) {
        return -1
      }
      return 0
    })
  }
  return eventBids.sort((firstBid, secondBid) => {
    if (parseFloat(firstBid.total) < parseFloat(secondBid.total)) {
      return 1
    }
    if (parseFloat(firstBid.total) > parseFloat(secondBid.total)) {
      return -1
    }
    return 0
  })
}

export const sortedBids = (lotBids: Bid[]) => {
  return lotBids.sort((firstBid, secondBid) => {
    const firstBidDate = new Date(firstBid.createdAt)
    const secondBidDate = new Date(secondBid.createdAt)
    if (firstBidDate < secondBidDate) {
      return 1
    }
    if (firstBidDate > secondBidDate) {
      return -1
    }
    return 0
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortByKey = (array: any[], key: string) =>
  array.concat().sort((el1, el2) => {
    if (el1[key] > el2[key]) return 1
    else if (el1[key] < el2[key]) -1
    return 0
  })

export const getLiveEventsEntered = (liveEvents: Event[]) => {
  let liveEventsCount = 0
  liveEvents.forEach((event: Event) => {
    const lotHasBids = event.catalogue.find((lot: Lot) => lot.bids && lot.bids.length > 0)
    if (lotHasBids) {
      liveEventsCount = liveEventsCount + 1
    }
  })
  return liveEventsCount
}

export const evaluateSearchUrl = (searchTerm: string, type: string, url: string) => {
  switch (type) {
    case 'Company':
    case 'User':
    case 'Event':
      if (searchTerm.length > 0) {
        return `/admin/${searchTerm}/${url}`
      }
      return ''
    default:
      return ''
  }
}

export const editingPage = (pageUrl: string, userId?: string) => {
  if (userId) {
    return !isEmpty(useMatch(generatePath(pageUrl, { userId })))
  }
}

export const countryPhoneCode = (country?: string) => {
  return country ? countryISO
    .find(
      ({ countryName }: { countryName: string }) => 
        countryName.toLowerCase() === country.toLowerCase()
    )?.phoneCode : ''
}

export const hasAssociatedCompany = (user: User) => 
  user.company ? 
    Object.values(user.company).length > 0
    :
    false

export const hasAdditionalBuyersInformation = (user: User) => 
  user.positionInCompany ? 
    user.positionInCompany.length > 0
    :
    false

export const hasRequiredDocuments = (user: User) => {
  const certificateOfIncorporation = user.documents?.find((reqDoc: Document) => reqDoc.name === "certificateOfIncorporation")
  const registrationCertificate= user.documents?.find((reqDoc: Document) => reqDoc.name === "registrationCertificate")
  const diamondDealersLicense = user.documents?.find((reqDoc: Document) => reqDoc.name === "diamondDealersLicense")

  return certificateOfIncorporation && registrationCertificate && diamondDealersLicense
}

export const userAMLDocuements = (user: User) => {
  const certificateOfIncorporation = user.documents?.find((reqDoc: Document) => reqDoc.name === "certificateOfIncorporation")
  const registrationCertificate= user.documents?.find((reqDoc: Document) => reqDoc.name === "registrationCertificate")
  const diamondDealersLicense = user.documents?.find((reqDoc: Document) => reqDoc.name === "diamondDealersLicense")

  return {
    certificateOfIncorporation, 
    registrationCertificate,
    diamondDealersLicense
  }
}

export const hasBuyersAgreement = (user: User) => {
  const buyersAgreement = user.documents?.find((reqDoc: Document) => reqDoc.name === "buyersAgreement")
  return buyersAgreement ? Object.values(buyersAgreement).length > 0 : false
}

export const userBuyersAgreement = (user: User) => {
  return user.documents?.find((reqDoc: Document) => reqDoc.name === "buyersAgreement")
}

export const userPassportIdDocument = (user: User) => {
  return user.documents?.find((reqDoc: Document) => reqDoc.name === "passportIdDocument")
}

export const hasCompletedOnBoarding = (user: User) => {
  return hasRequiredDocuments(user) && hasBuyersAgreement(user)
}

export const onBoardingStep = (user: User) => {
  if (!hasAssociatedCompany(user)) {
    return ONBOARDING_STEPS.COMPANY_STEP
  } else if (!hasAdditionalBuyersInformation(user)) {
    return ONBOARDING_STEPS.ADD_BUYERS_STEP
  } else if (!hasRequiredDocuments(user)) {
    return ONBOARDING_STEPS.REQUIRED_DOCS_STEP
  } else {
    return ONBOARDING_STEPS.BUYERS_AGREEMENT_STEP
  }
}

