import React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import Footer from "../../components/Footer"
import MainNavigation from "../../components/Header/MainNavigation"
import { useAuthContext } from "../../context/AuthProvider"
import { SITE_PAGE_URL } from "../../utils"

const SharedProtectedRoute: React.FC = () => {
  const location = useLocation()
  const { isAuthenticated } = useAuthContext()

  if (!isAuthenticated) {
    return <Navigate to={SITE_PAGE_URL.LOGIN_PAGE} replace state={{ from: location }} />;
  }

  return (
    <>
      <MainNavigation />
      <Outlet />
      <Footer />
    </>
  )
}
export default SharedProtectedRoute;