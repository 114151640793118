import React from "react"
import { generatePath, Navigate, Outlet, useLocation, useParams } from "react-router-dom"
import Footer from "../../components/Footer"
import MainNavigation from "../../components/Header/MainNavigation"
import { USERS_ROLES } from "../../constants"
import { useAuthContext } from "../../context/AuthProvider"
import { hasRoles, SITE_PAGE_URL } from "../../utils"

const KoinClientsProtectedRoute: React.FC = () => {
  const { eventId } = useParams()
  const location = useLocation()
  const { isAuthenticated, user } = useAuthContext()

  if (!isAuthenticated || !hasRoles(user, Object.values(USERS_ROLES))) {
    return <Navigate to={SITE_PAGE_URL.LOGIN_PAGE} replace state={{ from: location }} />;
  }
  if (isAuthenticated) {
    if (hasRoles(user, [USERS_ROLES.ADMIN])) {
      return <Navigate to="/events" replace state={{ from: location }} />;
    }
  }

  if (eventId) {
    if (location.pathname === generatePath('/results/:eventId', { eventId })) {
      return (
        <Outlet />
      )
    }
  }
  return (
    <>
      <MainNavigation />
      <Outlet />
      <Footer />
    </>
  )
}
export default KoinClientsProtectedRoute;