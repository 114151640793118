import { Box, Grid, GridItem, HStack, VStack, Text } from '@chakra-ui/react'
import { File } from 'phosphor-react'
import React from 'react'
import { theme } from '../../theme'
import { Notification } from '../../types'

type NotificationCardProps = {
  notification: Notification
}

const NotificationCard: React.FC<NotificationCardProps> = ({ notification }) => {
  return (
    <Grid width={'100%'} marginBottom={{ base: '10px' }} cursor="pointer" bgColor={'#1A5794'}>
      <GridItem>
        <VStack
          bgColor={'#113A63'}
          width={'100%'}
          borderRadius={'4px'}
          paddingY={'17.5px'}
          paddingX={'20px'}
        >
          <Box width={'100%'}>
            <HStack>
              <Box
                as="button"
                bg={theme.colors.primary[400]}
                w="24px"
                h="24px"
                p="2px"
                borderRadius="full"
              >
                <File width="100%" height="100%" color={theme.colors.primary[400]} />
              </Box>
              <Text fontWeight={'bold'} textColor={theme.colors.white} marginBottom={'5px'}>
                {notification?.heading}
              </Text>
            </HStack>
            <Text>{notification?.content}</Text>
            {notification ? (
              <Text color={theme.colors.white} fontSize={'12px'} marginBottom={'5px'}>
                {new Date(notification.createdAt).toDateString()}
              </Text>
            ) : null}
          </Box>
        </VStack>
      </GridItem>
    </Grid>
  )
}

export default NotificationCard
