import React, { Dispatch, SetStateAction } from 'react'

type AppContextType = {
  drawerOpen: boolean
  toggleDrawer: () => void

  formStep: number
  setFormStep: Dispatch<SetStateAction<number>>
}

export const AppContext = React.createContext<AppContextType>({
  drawerOpen: false,
  toggleDrawer: () => null,

  formStep: 0,
  setFormStep: () => null
})

export const useAppContext = () => React.useContext(AppContext)

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [formStep, setFormStep] = React.useState(0)

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <AppContext.Provider value={{ drawerOpen, toggleDrawer, formStep, setFormStep }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
