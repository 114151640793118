/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios'
import { fetchJwt } from '.'
import { BASE } from '../constants'

export type APILoginPayload = {
  data: string
  expires: number
  success?: boolean
}

// eslint-disable-next-line
const forgotPassword = async (email: string): Promise<AxiosResponse<any>> => {
  try {
    return await axios
      .post(BASE + '/users/forgot-password', {
        email
      })
      .catch((err) => {
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(err.response.status + ':' + err.response.data.data)
        } else if (err.request) {
          // The request was made but no response was received
          // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(err.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', err.message)
        }
        return err.config
      })
  } catch (error) {
    return Promise.reject(error)
  }
}

const resetPassword = async (token: string, password: string) => {
  try {
    return await axios
      .post(
        BASE + '/users/reset-password',
        {
          password
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .catch((err) => {
        if (err.response) {
          console.error(err.response.status + ':' + err.response.data.data)
        } else if (err.request) {
          console.error(err.request)
        } else {
          console.error('Error', err.message)
        }
        return err.config
      })
  } catch (error) {
    return Promise.reject(error)
  }
}

const verifyEmail = async (otp: string) => {
  try {
    return await axios.post(BASE + '/users/verify', { otp }).catch((err) => {
      if (err.response) {
        console.error(err.response.status + ':' + err.response.data.data)
      } else if (err.request) {
        console.error(err.request)
      } else {
        console.error('Error', err.message)
      }
      return err.config
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const login = async (email: string, password: string): Promise<AxiosResponse<APILoginPayload>> => {
  try {
    return await axios
      .post(BASE + '/users/login', {
        email,
        password
      })
      .catch((err) => {
        if (err.response) {
          console.error(err.response.status + ':' + err.response.data.data)
        } else if (err.request) {
          console.error(err.request)
        } else {
          console.error('Error', err.message)
        }
        return err.config
      })
  } catch (error) {
    return Promise.reject(error)
  }
}

const register = async (
  firstname: string,
  lastname: string,
  phoneNumber: string,
  email: string,
  password: string
): Promise<AxiosResponse<APILoginPayload>> => {
  try {
    return await axios
      .post(BASE + '/users', {
        firstname,
        lastname,
        mobileNumber: phoneNumber,
        email,
        password
      })
      .catch((err) => {
        if (err.response) {
          console.error(err.response.status + ':' + err.response.data.data)
        } else if (err.request) {
          console.error(err.request)
        } else {
          console.error('Error', err.message)
        }
        return err.config
      })
  } catch (error) {
    return Promise.reject(error)
  }
}

const upload = async (
  file: File,
  onUploadProgress?: (progressEvent: ProgressEvent, file: File) => void
  // eslint-disable-next-line
): Promise<AxiosResponse<any[]>> => {
  const formData = new FormData()
  formData.append('files', file)
  try {
    return await axios
      .post(BASE + '/upload', formData, {
        onUploadProgress: (event) => onUploadProgress && onUploadProgress(event, file),
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${fetchJwt()}`
        }
      })
      .catch((err) => {
        if (err.response) {
          console.error(err.response.status + ':' + err.response.data.data)
        } else if (err.request) {
          console.error(err.request)
        } else {
          console.error('Error', err.message)
        }
        return err.config
      })
  } catch (error) {
    return Promise.reject(error)
  }
}

export default { forgotPassword, resetPassword, verifyEmail, login, register, upload }
